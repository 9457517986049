import { useCallback, useEffect, useState } from "react"

import {
  NeoComponents,
  isEmpty,
  showToastr
} from "../../forcepoint-platform-utilityui"
import { NeoMultiSelectItemProps } from "@neo/neo-common-ui/lib/components/neo-multi-select/NeoMultiSelectList"
import "./style.css"
import React from "react"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

type IList = {
  value: string
  text: string
}

interface ISearchInput {
  id: number | string
  list: IList[]
  onChange: (val: IList[]) => void
  onError: (val) => void
  shouldFilterItems?: boolean
  onBlur?: (e) => void
  onLimitExceed: (val) => void
  validatorFn: (val) => boolean
  limit: number
  objectName?: string
}
const SearchInput = ({
  id,
  list = [],
  onChange,
  onLimitExceed,
  limit = 0,
  onError,
  validatorFn,
  shouldFilterItems,
  onBlur = (e) => {
    // e.preventDefault()
  },
  objectName,
  ...props
}: ISearchInput): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<NeoMultiSelectItemProps[]>(
    []
  )
  const [searchText, setSearchText] = useState(null)

  useEffect(() => {
    // This useEffect will run whenever id changes
    // to remove "Remove from list" text when we click on "Add Custom Url Category" Button
    setSelectedItems([])
  }, [id])

  const handleClick = useCallback((item) => {
    setSelectedItems((items) => {
      const selected = items.findIndex((a: any) => a.value === item.value)
      const updatedList = [...items]
      if (selected === -1) {
        updatedList.push(item)
      } else {
        updatedList.splice(selected, 1)
      }
      return updatedList
    })
  }, [])

  const handleAddNewValue = (value) => {
    if (isEmpty(value)) return
    let updatedList = []
    if (list.length) {
      const isDuplicate = list.findIndex((item) => item.value === value) !== -1
      const toastConfig = {
        type: "error",
        message: "DOOP"
      }
      if (isDuplicate) {
        showToastr(toastConfig)
        return
      }
    }
    if (validatorFn(value)) {
      if (list.length && limit && list.length >= limit) {
        onLimitExceed(value)
        return
      }

      if (list.length) {
        updatedList = [...list]
      }

      updatedList.push({
        text: value,
        value: value
      })

      onChange(updatedList)
      setSearchText(null)
    } else {
      onError(value)
    }
  }

  let filteredList = null
  if (!shouldFilterItems && searchText && searchText !== "") {
    filteredList = list.filter(
      (item) =>
        item?.text?.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
    )
  }

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="multi-select-list-container">
            <NeoComponents.NeoSingleSelectList
              items={filteredList ?? list}
              alwaysShowSearchBar={true}
              selected={selectedItems}
              shouldFilterItems={shouldFilterItems}
              onSearch={(value) => {
                setSearchText(value)
              }}
              onItemClicked={handleClick}
              // searchOnKeyDown={(e: any) => {
              //   if (e.key === "Enter") {
              //     e.preventDefault()
              //     handleAddNewValue(e.target.value)
              //     e.target.value = ""
              //   }
              // }}
              ItemListComponent={ItemListComponentWithTooltip}
              EmptyStateComponent={({ searchText, clearHandler }) => {
                if (!isEmpty(searchText)) {
                  return (
                    <a
                      className="scheduler-anchor add-data "
                      href=""
                      onClick={(e) => {
                        e.preventDefault()
                        handleAddNewValue(searchText)
                        clearHandler()
                      }}
                    >
                      {/* {`Add ${searchText.length > 32 ? searchText.substring(0, 32) + "..." : searchText} to list`} */}
                      {`Add ${searchText} to list`}
                    </a>
                  )
                } else if (list.length === 0 && objectName) {
                  // Returns a component stating the list is empty
                  return (
                    <div className="d-flex justify-content-center align-items-center h-100 text-align-center flex-column">
                      <img
                        src="/assets/images/empty-default.png"
                        alt="An exclamation point in a circle"
                        style={{ width: "120px" }}
                      />
                      <span style={{ color: "var(--teal-200)" }}>
                        No {objectName} have been added yet.
                      </span>
                    </div>
                  )
                } else return <></>
              }}
              // searchOnBlur={onBlur}
              {...props}
            />

            {/* Functionality for Removal of list items */}
            <div className="multi-select-footer-container">
              {selectedItems?.length > 0 ? (
                <>
                  <button
                    className="remove-from-list"
                    onClick={(e) => {
                      e.preventDefault()
                      setSelectedItems([])
                    }}
                  >
                    Unselect All
                  </button>
                  <button
                    className="remove-from-list"
                    onClick={(e) => {
                      e.preventDefault()
                      const updatedList = [...list].filter(
                        (el) => !selectedItems.includes(el)
                      )
                      setSelectedItems([])
                      onChange(updatedList)
                    }}
                  >
                    Remove from list
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

function arePropsEqual(old, newProps) {
  return old.item.value === newProps.item.value
}

const ItemListComponentWithTooltip = React.memo(function ({
  item
}: {
  children?: React.ReactNode
  item: { text: string; value: string }
}) {
  return (
    <span
      data-dark-html-tip-at="left"
      data-line-height="18px"
      data-margin="2px"
      data-padding="8px 12px"
      data-white-space="nowrap"
      data-word-break="normal"
      data-always="true"
      data-dark-html-tip={item.text.length > 20 ? item.text : null}
    >
      {item.text}
    </span>
  )
}, arePropsEqual)

// REMOVE: for building

export default SearchInput
