import styled from "styled-components"

export const DrawerChildrenContainer = styled.div<{
  headerHeight?: number
  marginBottom?: number
}>`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.scrollbar};
  background-color: rgba(94, 120, 141, 0.05);
  height: calc(
    100% -
      ${({ headerHeight = 0, marginBottom = 0 }) =>
        `${headerHeight + marginBottom}px`}
  );
`

export const Title = styled.h3`
  margin: 0 auto;
  color: ${({ theme }) => theme.neutral.lightSlate};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight.demiBold};
  line-height: 16px;
`
