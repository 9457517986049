/**
 * To store messages
 */
export const Messages: any = {
  required: "{name} cannot be blank",
  min: "{name} should be greater than or equal to {min}",
  max: "{name} should be less than or equal to {max}",
  minLength: "{name} cannot be less than {min} characters",
  maxLength: "{name} cannot be longer than {max} characters",
  minValue: "{name} cannot be less than {min}",
  maxValue: "{name} cannot be greater than {max}",
  pattern: "{name} is not valid"
}
