import { useRef } from "react"
import { NeoPopupMenu } from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../forcepoint-platform-utilityui"

/**
 * To get option button
 * @param props
 * @returns
 */
export const GetOptionBtn = ({ config, actionBtnHandler }) => {
  const menuButton = config.menuButton
  const menuPopup = config.menuPopup
  if (menuPopup.menuItems) {
    menuPopup.menuItems = menuPopup.menuItems.map((val) => {
      val.menuCallback = () => actionBtnHandler(val)
      return val
    })
  }

  let openMenu = false
  const optionContainerRef = useRef(null)

  const menuConfig = {
    menuButton,
    menuPopup,
    onToggle: () => {
      openMenu = !openMenu
    }
  }
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div
            role="button"
            tabIndex={0}
            id="kebab_menu_btn"
            ref={optionContainerRef}
            className="hover-option-menu"
            onMouseEnter={() => {
              if (!openMenu) {
                optionContainerRef.current
                  .querySelector("#" + menuButton.id)
                  .click()
                openMenu = true
              }
            }}
            onMouseLeave={() => {
              if (openMenu) {
                optionContainerRef.current
                  .querySelector("#" + menuButton.id)
                  .click()
                openMenu = false
              }
            }}
          >
            <NeoPopupMenu {...menuConfig} />
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
