import { showToastr } from "../app.constants"
import { isIPRange, isIP } from "./ipValidator"
import download from "downloadjs"

// export enum fileHeaders {
//   URL_data = "URL_data",
//   IP_data = "IP_data",
//   REGEX_URL_data = "REGEX_URL_data"
// }

export const ipValidator = (value: string): boolean => {
  return isIPRange(value.trim()) || isIP(value.trim())
}
export const urlValidator = (data: string): boolean => {
  const urlPattern =
    /\*?(?:https?:\/\/)?(?:www\.)?(?:\*\.)?[a-zA-Z0-9]+\*?\.[a-zA-Z]+(?:\/[^\s]*)?/
  return urlPattern.test(data)
}

export const regexUrlValidator = (data: string): boolean => {
  try {
    if (new RegExp(data)) {
      return true
    }
  } catch (e) {
    return false
  }
}

const defaultMessages: { [messageKey: string]: string } = {
  DUPLICATE: "Duplicate Entry",
  INVALID: "Invalid Entry",
  LIMIT: "Limit has been exceeded",
  DUPLICATE_TOAST: "Duplicate Entries",
  INVALID_TOAST: "Invalid Entries",
  INVALID_HEADERS: "Invalid column header. Please follow Sample file",
  DUPLICATE_AND_INVALID_TOAST: "Duplicate and Invalid Entries",
  NO_DATA: "No Data provided"
}

function processCSVFirstColumn(content: string): string {
  const lines = content.split('\n');
  const firstColumnData = lines.map(line => {
    const parts = line.split(',');
    return parts[0].trim();
  }).join('\n'); // Join all the first elements into a single string separated by spaces
  return firstColumnData;
}

/**
 *
 * @param files All files uploaded by user
 * @param messages Error messages to be shown
 * @param list State containing list of items in {name:string, id:string} form
 * @param validator Validator function returning true when valid
 * @param limit Max entries to allow from a file upload
 * @returns Valid data in {name:string, id:string} form
 */
export const handleFileUpload = (
  files: Blob[],
  messages: { [messageKey: string]: string },
  list: { text: string; value: string }[],
  validator: (arg0: string) => boolean,
  limit = null,
  objectName = "Data"
): Promise<any> => {
  return new Promise((res) => {
    if (Array.isArray(files) && files.length) {
      const file = files[0] as File; // Cast Blob to File to access the name property
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          let content = e.target.result as string;

          // Check if the file is a CSV by looking at the file extension
          if (file.name.endsWith('.csv')) {
            // Process only the first column of the CSV content
            content = processCSVFirstColumn(content);
          }
          const validData = validateDataAndShowErrors(
            content,
            list,
            validator,
            limit,
            objectName,
            messages
          );
          res(validData);
        }
      };
      reader.readAsText(file);
    }
  })
}

function addQuotesToCSVData(data) {
  return `"${data}"`;
}

const validateDataAndShowErrors = (
  content: string,
  list: { text: string; value: string }[],
  validator: (arg0: string) => boolean,
  limit: number,
  objectName: string,
  messages = defaultMessages
) => {
  const result = {
    // invalidHeaders: { status: false },
    invalidData: { status: false },
    duplicate: { status: false },
    reachedLimit: { status: false },
    list: { value: null }
  }

  const normalizedText = content.replace(/\r/g, "")
  const uploadedData = normalizedText
    .split("\n")
    .filter((value) => value.trim() !== "")
  // If file is empty
  if (!uploadedData.length) {
    showToastr({
      type: "error",
      // message: "Uploaded file is empty"
      message: messages.NO_DATA
    })
    return
  }

  // Stores all data
  let dataToBeChecked = uploadedData
  // If limit exists, only validate for limit number of entries
  if (limit && uploadedData.length > limit) {
    dataToBeChecked = uploadedData.slice(0, limit)
  }

  // const isValidHeader = (header) => {
  //   if (header.length == 1) {
  //     return header in fileHeaders
  //   }

  //   return false
  // }

  let errorCSV = "Data,Error\n"

  const validData = []
  // Checks each entry for duplicate or error
  dataToBeChecked.forEach((dataItem, index) => {
    // Stores all errors for the current entry
    const errors = []

    // if (!index) {
      // checking number of headers in top row. Should only be 1
      // const header = dataItem.split(",")

      // if (!isValidHeader(header)) {
      //   errors.push(messages.INVALID_HEADERS)
      //   result.invalidHeaders.status = true
      //   if (result.invalidHeaders.status) {
      //     showToastr({
      //       type: "error",
      //       message: messages.INVALID_HEADERS
      //     })
      //   }
      // }
    // }

    // if (index) {
      /* ignore 1st row of headers & validate from 2nd row  */
      if (
        list.some((item) => item.text === dataItem) ||
        dataToBeChecked.indexOf(dataItem) !== index
      ) {
        // Checks if entry already exists, or is duplicate in the uploaded file
        errors.push(messages.DUPLICATE)
        result.duplicate.status = true
      }

      // Checks if entry is valid based on validator func
      if (!validator(dataItem)) {
        errors.push(messages.INVALID)
        result.invalidData.status = true
      }

      //addQuotesToCSVData will ensure string is enclosed in double quotes, 
      //to maintain the integrity of complex strings within a single column in your CSV file
      const quotedString = addQuotesToCSVData(dataItem);

      // If entry has errors, append it to error csv
      if (errors.length > 0) {
        errorCSV += `${quotedString},"${errors.join(", ")}"\n`
      } else {
        validData.push(dataItem)
      }
    // }
  })
  // Add the entries that exceeded the limit to the end of the csv
  if (limit && uploadedData.length > limit) {
    errorCSV +=
      uploadedData.slice(limit).join(`,${messages.LIMIT}\n`) +
      `,${messages.LIMIT}\n`
    showToastr({ type: "error", message: messages.ERROR_LIMIT_REACHED })
  }

  // Errorscsv is 11 characters when no error is present, so if length is greater then error exists
  if (errorCSV.length > 11) {
    if (result.duplicate.status && result.invalidData.status) {
      showToastr({
        type: "error",
        message: messages.DUPLICATE_AND_INVALID_TOAST
      })
    } else if (result.duplicate.status) {
      showToastr({
        type: "error",
        message: messages.DUPLICATE_TOAST
      })
    } else if (result.invalidData.status) {
      showToastr({
        type: "error",
        message: messages.INVALID_TOAST
      })
    }

    const now = new Date()
    const parsedNow =
      now.toDateString().substring(4) +
      " " +
      now.toTimeString().substring(0, 8).replace(/:/g, ".")
    download(errorCSV, `invalid${objectName}Error-${parsedNow}.csv`, "text/csv")
  }

  // if (!result.invalidHeaders.status) {
    /* add the bulk data only if the column header is valid */
    result.list.value = validData.map((data) => {
      return {
        text: data,
        value: data
      }
    })
  // }

  return result
}

//limit to add/paste/upload data into IPs/URLs/RegexURLs
export const limit = 1000

//handle Paste for address field
export const handlePaste = (
  list,
  messages,
  validator,
  limit = null,
  objectName = "Data"
) => {
  return new Promise((res, rej) => {
    if (navigator.clipboard?.readText) {
      // Chrome: Use navigator.clipboard API
      //extracting copied data from the clipboard
      navigator?.clipboard
        ?.readText()
        .then((text) => {
          const validData = validateDataAndShowErrors(
            text,
            list,
            validator,
            limit,
            objectName,
            messages
          )
          const tempObj = {
            value: validData.list.value,
            status: true
          }
          res(tempObj)
        })
        .catch((e) => {})
    } else {
      // Firefox or unsupported browsers: Use user prompts
      // const pastedContent = prompt("Please paste the content here:")
      // setPastedText(pastedContent);
    }
  })
}

///handle FileDownload for address field
export const handleFileDownload = (list, name = "") => {
  const textData = Array.isArray(list)
    ? list.map((obj) => obj.name || obj.text).join("\n")
    : list

  const now = new Date().toDateString()
  const parsedNow =
    now.substring(8, 10) + now.substring(4, 7) + now.substring(11)

  download(textData, `All_${name}_${parsedNow}.csv`, "text/csv")
}

///handle sample FileDownload for address fields
export const handleSampleFileDownload = (list, name = "") => {
  const textData = Array.isArray(list)
    ? list.map((obj) => obj.name || obj.text).join("\n")
    : list

  download(textData, `${name}_Import_Format_Sample.csv`, "text/csv")
}
