import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  ReactNode,
  CSSProperties
} from "react"
import { DrawerChildrenContainer, Title } from "./InlineDrawerStyles"
import styled from "styled-components"
import DefaultDrawerHeader from "./DefaultDrawerHeader"
import ReactDOM from "react-dom"
import { NeoIcon } from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Drawer = styled.div<{ drawerWidth?: string }>`
  width: ${({ drawerWidth = "50%" }) => drawerWidth};
  height: 100%;
  transition: ${({ theme }) => theme.transitions.normal};
  overflow: hidden;
  z-index: 1000;
`

const BackdropDiv = styled.div<{
  backdropZIndex: number
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${({ backdropZIndex }) => backdropZIndex};
  background: transparent;
`

const Backdrop: React.FC<{
  backdropZIndex: number
  onClose: () => void
}> = ({ backdropZIndex, onClose }) => {
  return <BackdropDiv backdropZIndex={backdropZIndex} onClick={onClose} />
}

export interface InlineDrawerHeaderProp {
  title?: string
  handleCloseDrawer?: () => void
  customActionMenu?: any
  children?: any
}

export interface InlineDrawerExpanderProp {
  isExpanded?: boolean
  onClick?: (isExpanded: boolean) => void
}

type InlineDrawerProps = {
  isOpen: boolean
  closeDrawerCallback?: () => void
  title?: string
  isExpanded?: boolean
  expandedCallback?: (expanded: boolean) => void
  minWidth?: string
  maxWidth?: string
  customHeader?: React.FC<InlineDrawerHeaderProp>
  customExpander?: React.FC<InlineDrawerExpanderProp>
  showExpandable?: boolean
  showRemoveButton?: boolean
  hideHeader?: boolean
  headerComponent?: ReactNode
  testId?: string
  style?: CSSProperties
  children?: ReactNode | ReactNode[]
  useBackdrop?: boolean
  backdropZIndex?: number
  onBackdropClick?: () => void
  customActionMenu?: any
}

const HeaderContainer = styled.div``

const InlineDrawer: React.FC<InlineDrawerProps> = ({
  isOpen,
  isExpanded = false,
  closeDrawerCallback,
  expandedCallback,
  minWidth,
  maxWidth,
  title,
  customActionMenu,
  customHeader: CustomHeader = ({
    title,
    handleCloseDrawer,
    customActionMenu,
    children
  }: InlineDrawerHeaderProp) => (
    <DefaultDrawerHeader
      title={title}
      handleCloseDrawer={handleCloseDrawer}
      customActionMenu={customActionMenu}
    />
  ),
  customExpander: CustomExpander,
  showExpandable = false,
  showRemoveButton = true,
  hideHeader = false,
  headerComponent,
  testId,
  style,
  useBackdrop = false,
  backdropZIndex = 200,
  onBackdropClick,
  children,
  ...rest
}) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [headerRef?.current?.clientHeight])

  // We use handleCloseDrawer to manually handle drawer unmount
  const handleCloseDrawer = useCallback(() => {
    closeDrawerCallback && closeDrawerCallback()
  }, [closeDrawerCallback])

  // add CB to reuse on component - used only when CustomHeader provided
  const handleExpandDrawer = useCallback(() => {
    expandedCallback && expandedCallback(!isExpanded)
  }, [isExpanded, expandedCallback])

  const drawerWidth = !isOpen ? "0" : isExpanded ? maxWidth : minWidth
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <Drawer
            id="drawer"
            drawerWidth={drawerWidth}
            style={style}
            {...rest}
            test-id={testId}
          >
            <div style={{ width: "100%", height: "100%" }}>
              {!hideHeader && (
                <HeaderContainer ref={headerRef}>
                  <CustomHeader
                    title={title}
                    handleCloseDrawer={closeDrawerCallback}
                    customActionMenu={customActionMenu}
                  >
                    {showRemoveButton && (
                      <NeoIcon
                        size={18}
                        name="close"
                        onClick={handleCloseDrawer}
                      />
                    )}
                    <Title className="drawer-title">{title}</Title>
                    {!!headerComponent && headerComponent}
                    {showExpandable && CustomExpander && (
                      <CustomExpander
                        isExpanded={isExpanded}
                        onClick={handleExpandDrawer}
                      />
                    )}
                    <div>trst</div>
                  </CustomHeader>
                </HeaderContainer>
              )}
              <DrawerChildrenContainer
                data-testid={`${testId}_inline_drawer`}
                headerHeight={headerHeight || 48}
                marginBottom={24}
                id={testId}
              >
                {children}
              </DrawerChildrenContainer>
            </div>
            {useBackdrop &&
              ReactDOM.createPortal(
                <Backdrop
                  backdropZIndex={backdropZIndex}
                  onClose={() =>
                    onBackdropClick ? onBackdropClick() : closeDrawerCallback()
                  }
                />,
                // @ts-ignore
                document.getElementById("backdrop-root")
              )}
          </Drawer>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default React.memo(InlineDrawer)
