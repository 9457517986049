import { ToastContainer } from "react-toastify"

type ToastWithBoldNameProps = {
  txt: string
  name: string
  isBold?: boolean
}

type ToastMessageWithDataProps = {
  message: string
  data: { [property: string]: string }
  isBold?: boolean
}

const Toastr = () => {
  return <ToastContainer />
}

export default Toastr

//Temporary global component for SSE UIs
const ToastWithBoldName = ({
  txt,
  name,
  isBold = true
}: ToastWithBoldNameProps) => {
  if (!isBold && name) return <span>{txt?.replace("{name}", name)}</span>

  const seg = txt?.split("{name}")
  return (
    <>
      {name && (
        <div>
          {seg[0]} "
          <span className="fw-bold" style={{ wordBreak: "break-all" }}>
            {name}
          </span>
          " {seg[1]}
        </div>
      )}
    </>
  )
}

/**
 * Replaces variables in a provided message, based on a data map provided. Optionally turn these variables bold
 * @param message prop containing template string with variables like {name}
 * @param data prop containing data as {variable: value} shaped object
 * @param isBold boolean specifying if replaced values should be in bold or not
 * @returns a JSX element containing a message with replaced variables
 */
const ToastMessageWithData = ({
  message,
  data,
  isBold = true
}: Readonly<ToastMessageWithDataProps>) => {
  const dataEntries = data && Object.entries(data)

  if (!isBold) {
    let newMessage = message
    dataEntries &&
      dataEntries.length &&
      dataEntries.forEach(([key, value]) => {
        newMessage = newMessage.replace(`{${key}}`, value)
      })
    return <span>{newMessage}</span>
  }
  const r = /(?:{.*?})/
  return (
    <div>
      {message.split(" ").map((word) => {
        const [mainWord, rest] = word.split(/{|}/)
        if (r.test(word)) {
          return (
            <>
              {mainWord && (
                <span className="fw-bold" style={{ wordBreak: "break-all" }}>
                  {mainWord}
                </span>
              )}
              {rest && rest === "?" ? "?" : ""}
            </>
          )
        }

        return word + " "
      })}
    </div>
  )
}

export { ToastWithBoldName, ToastMessageWithData }
