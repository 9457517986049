import { useState, useRef } from "react"
import NeoIcon from "@neo/neo-common-ui/lib/components/neo-icon/NeoIcon"
import { NeoSecondaryButton } from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

type allowedFileTypeAndErrorMessageType = {
  accept: string
  validatorRegex: RegExp
  errorMessage: string
}

type UploadFileProps = {
  title: string
  subtitle: string
  displayIconHelp: boolean
  multiFileUpload: boolean
  handleUpload: (selectedFiles: any[]) => void
  onClose: () => void
  handleDownloadSample: () => void
  allowedFileTypeAndErrorMessage: allowedFileTypeAndErrorMessageType
}

// write the logic to check isValidFile for multiFileUpload
const UploadFile = ({
  title,
  subtitle,
  displayIconHelp,
  multiFileUpload = false,
  handleUpload,
  onClose,
  handleDownloadSample,
  allowedFileTypeAndErrorMessage
}: UploadFileProps) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const fileInputRef = useRef(null)

  /**
   * checks following conditions to see if file is valid
   * 1. If accept is *, all file types will be accepted
   * 2. Checks if file extension is present in the accept string
   * 3. If validatorRegex is present, checks if the filename adheres to that regex
   */
  let isValidFile =
    !allowedFileTypeAndErrorMessage.accept.includes("*") &&
    allowedFileTypeAndErrorMessage.accept.includes(
      selectedFiles?.[0]?.name.split(".").pop()
    )

  if (
    selectedFiles.length > 0 &&
    allowedFileTypeAndErrorMessage.validatorRegex &&
    !allowedFileTypeAndErrorMessage.validatorRegex?.test(
      selectedFiles?.[0]?.name
    )
  ) {
    isValidFile = false
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const files = Array.from(e.dataTransfer.files)
    setSelectedFiles(files)
  }

  const handleInputChange = (e) => {
    const files = Array.from(e.target.files)
    setSelectedFiles(files)
  }

  const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles]
    newFiles.splice(index, 1)
    setSelectedFiles(newFiles)
  }

  const handleSubmit = () => {
    handleUpload(selectedFiles)
    onClose()
  }

  const handleBrowseClick = () => {
    fileInputRef.current.click()
  }

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="upload-modal">
            <div className="upload-modal-overlay" onClick={onClose}></div>
            <div className="upload-dialog">
              <div className="dialog-header">
                <div className="title">{title || ""}</div>
                <NeoIcon
                  name="close"
                  onClick={onClose}
                  size={14}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="subtitle">{subtitle || ""}</div>
              <div className="dialog-body">
                <div
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    border: "2px dashed #ccc",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "#f9fafb",
                    borderRadius: "10px"
                  }}
                >
                  <p className="message">Drag and Drop files here!</p>
                  <input
                    type="file"
                    accept={allowedFileTypeAndErrorMessage.accept}
                    multiple={multiFileUpload}
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <NeoSecondaryButton
                    onClick={handleBrowseClick}
                    id="browse-button"
                  >
                    Browse
                  </NeoSecondaryButton>
                </div>
              </div>
              {selectedFiles.length > 0 && (
                <div>
                  <p className="mt-2">{`Selected File${
                    selectedFiles.length > 1 ? "s" : ""
                  }: `}</p>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li
                        key={file.name}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "2rem"
                        }}
                      >
                        <p
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                          data-dark-tip={file.name}
                        >
                          {file.name}
                        </p>
                        <NeoIcon
                          name="close"
                          onClick={() => handleFileRemove(index)}
                          size={10}
                          style={{ cursor: "pointer", display: "inline-block" }}
                        />
                      </li>
                    ))}
                  </ul>
                  {!isValidFile ? (
                    <ul>
                      <li style={{ color: "red" }}>
                        {allowedFileTypeAndErrorMessage.errorMessage}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {displayIconHelp && (
                <div className="help-container">
                  <NeoIcon
                    name="outline_info"
                    size={14}
                    fill="#19aee3"
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginRight: "10px"
                    }}
                  />
                  <span className="sample-download">
                    For more information on how to import a file,{" "}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault()
                        handleDownloadSample()
                      }}
                    >
                      Sample File
                    </a>
                  </span>
                </div>
              )}
              <div className="dialog-footer">
                <NeoSecondaryButton
                  onClick={handleSubmit}
                  id="cancel-button"
                  isDisabled={!isValidFile}
                >
                  Submit
                </NeoSecondaryButton>
                <NeoSecondaryButton onClick={onClose} id="cancel-button">
                  Cancel
                </NeoSecondaryButton>
              </div>
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default UploadFile
