import { PopupComponentProps } from "./PopupComponent"
import ItemBox from "./ItemBox"
import { PopupMainContainer } from "../InlineDrawer/StyleComponents"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
interface PopupContainerProps extends PopupComponentProps {
  item2slice?: number
}

const PopupContainer = ({ items, item2slice = 1 }: PopupContainerProps) => {
  const sortedItems = items.slice(item2slice)

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <PopupMainContainer wrap={items?.[0].wrap}>
            {sortedItems.map((item) => {
              return <ItemBox key={item.name} item={item} />
            })}
          </PopupMainContainer>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default PopupContainer
