import { NeoButtonPopup } from "@neo/neo-common-ui/lib"
import { NeoPopupPosition } from "@neo/neo-common-ui/lib/components/neo-popover/NeoButtonPopup"
import PopupContainer from "./PopupContainer"
import ItemBox from "./ItemBox"
import {
  Container,
  TextContainer,
  MoreContainer
} from "../InlineDrawer/StyleComponents"
import TableColumnMoreBtn from "./TableColumnMoreBtn"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

export interface PopupComponentProps {
  items: {
    name: string
    color: string
    wrap?: boolean
    hideItems?: boolean
    otherTextStyle?: string
  }[]
  otherTextStyle?: string
}

const PopupComponent = ({
  items = [],
  otherTextStyle
}: PopupComponentProps) => {
  const sortedItems =
    items &&
    items.length &&
    items?.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
  const topItem = sortedItems?.[0] || null

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <Container>
            {topItem && (
              <>
                <TextContainer>
                  <ItemBox item={topItem} />
                </TextContainer>
                {sortedItems?.length > 1 && (
                  <MoreContainer>
                    <NeoButtonPopup
                      buttonComponent={
                        <TableColumnMoreBtn amount={sortedItems?.length - 1} />
                      }
                      popupComponent={
                        topItem?.hideItems ? (
                          <></>
                        ) : (
                          <PopupContainer items={sortedItems} />
                        )
                      }
                      popupOffset={{ distance: 8, skidding: -4 }}
                      popupPosition={NeoPopupPosition.BOTTOM_END}
                    />
                  </MoreContainer>
                )}
              </>
            )}
          </Container>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default PopupComponent
