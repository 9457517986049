// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtered {
  font-size: 12px;
  margin-bottom: 10px;
  padding: 4px 0px;
  padding-left: 5px;
  border-radius: 5px;
}

.filtered:hover {
  background-color: #f3f5f7;
}

.selected {
  background-color: #ecf8f6;
}

.add-data {
  color: #00af9a;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  word-break: break-all;
}

.add-data:hover {
  color: #00af9a;
}

.remove-from-list {
  color: #00af9a;
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.remove-from-list:hover {
  color: #00af9a;
  text-decoration: underline;
}

.multi-select-footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.multi-select-list-container .NeoMultiSelectList__Footer-sc-xvo26-7 {
  display: none;
}

.multi-select-list-container div[class*="NeoMultiSelectListResults__ListItem"] {
  max-width: 33vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchInput/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;EACf,6BAA6B;EAC7B,YAAY;AACd;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".filtered {\n  font-size: 12px;\n  margin-bottom: 10px;\n  padding: 4px 0px;\n  padding-left: 5px;\n  border-radius: 5px;\n}\n\n.filtered:hover {\n  background-color: #f3f5f7;\n}\n\n.selected {\n  background-color: #ecf8f6;\n}\n\n.add-data {\n  color: #00af9a;\n  font-size: 0.75rem;\n  font-weight: 600;\n  cursor: pointer;\n  word-break: break-all;\n}\n\n.add-data:hover {\n  color: #00af9a;\n}\n\n.remove-from-list {\n  color: #00af9a;\n  font-size: 0.75rem;\n  font-weight: 600;\n  text-decoration: none;\n  cursor: pointer;\n  background-color: transparent;\n  border: none;\n}\n.remove-from-list:hover {\n  color: #00af9a;\n  text-decoration: underline;\n}\n\n.multi-select-footer-container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 1rem;\n}\n\n.multi-select-list-container .NeoMultiSelectList__Footer-sc-xvo26-7 {\n  display: none;\n}\n\n.multi-select-list-container div[class*=\"NeoMultiSelectListResults__ListItem\"] {\n  max-width: 33vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
