const IPv4SegmentFormat = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';

const IPv4AddressFormat = `(${IPv4SegmentFormat}[.]){3}${IPv4SegmentFormat}`;

const IPv4AddressRegExp = new RegExp(`^${IPv4AddressFormat}$`);
 
const IPv6SegmentFormat = '(?:[0-9a-fA-F]{1,4})';

const IPv6AddressRegExp = new RegExp('^(' +

  `(?:${IPv6SegmentFormat}:){7}(?:${IPv6SegmentFormat}|:)|` +

  `(?:${IPv6SegmentFormat}:){6}(?:${IPv4AddressFormat}|:${IPv6SegmentFormat}|:)|` +

  `(?:${IPv6SegmentFormat}:){5}(?::${IPv4AddressFormat}|(:${IPv6SegmentFormat}){1,2}|:)|` +

  `(?:${IPv6SegmentFormat}:){4}(?:(:${IPv6SegmentFormat}){0,1}:${IPv4AddressFormat}|(:${IPv6SegmentFormat}){1,3}|:)|` +

  `(?:${IPv6SegmentFormat}:){3}(?:(:${IPv6SegmentFormat}){0,2}:${IPv4AddressFormat}|(:${IPv6SegmentFormat}){1,4}|:)|` +

  `(?:${IPv6SegmentFormat}:){2}(?:(:${IPv6SegmentFormat}){0,3}:${IPv4AddressFormat}|(:${IPv6SegmentFormat}){1,5}|:)|` +

  `(?:${IPv6SegmentFormat}:){1}(?:(:${IPv6SegmentFormat}){0,4}:${IPv4AddressFormat}|(:${IPv6SegmentFormat}){1,6}|:)|` +

  `(?::((?::${IPv6SegmentFormat}){0,5}:${IPv4AddressFormat}|(?::${IPv6SegmentFormat}){1,7}|:))` +

  ')(%[0-9a-zA-Z-.:]{1,})?$');
 
export function assertString(input) {
  const isString = typeof input === 'string' || input instanceof String;
  if (!isString) {
    let invalidType = typeof input;
    if (input === null) invalidType = null;
    else if (invalidType === 'object') invalidType = input.constructor.name;
    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
}

export function isIP(str, version='') {
  assertString(str);
  version = String(version);
  if (!version) {
    return isIP(str, '4') || isIP(str, '6');
  }
  if (version === '4') {
    return IPv4AddressRegExp.test(str);
  }
  if (version === '6') {
    return IPv6AddressRegExp.test(str);
  }
  return false;
}

const subnetMaybe = /^\d{1,3}$/;
const v4Subnet = 32;
const v6Subnet = 128;

export function isIPRange(str, version = '') {
  assertString(str);
  const parts = str.split('/');

  // parts[0] -> ip, parts[1] -> subnet
  if (parts.length !== 2) {
    return false;
  }

  if (!subnetMaybe.test(parts[1])) {
    return false;
  }

  // Disallow preceding 0 i.e. 01, 02, ...
  if (parts[1].length > 1 && parts[1].startsWith('0')) {
    return false;
  }

  const isValidIP = isIP(parts[0], version);
  if (!isValidIP) {
    return false;
  }

  // Define valid subnet according to IP's version
  let expectedSubnet = null;
  switch (String(version)) {
    case '4':
      expectedSubnet = v4Subnet;
      break;

    case '6':
      expectedSubnet = v6Subnet;
      break;

    default:
      expectedSubnet = isIP(parts[0], '6') ? v6Subnet : v4Subnet;
  }

  return parts[1] <= expectedSubnet && parts[1] >= 0;
}