import React from "react"
import styled, { useTheme } from "styled-components"
import { InlineDrawerHeaderProp } from "./InlineDrawer"
import { NeoCenterFlex, NeoIcon } from "@neo/neo-common-ui/lib"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const HeaderBox = styled(NeoCenterFlex)`
  padding: 24px;
  height: 48px;
  width: 100%;
  position: sticky;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 3;
  background-color: #fff;
`

const TitleHeaderBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Title = styled.h3`
  font-size: 16px;
  line-height: 16px;
  margin: 0 auto;
`

const DefaultDrawerHeader: React.FC<InlineDrawerHeaderProp> = ({
  handleCloseDrawer,
  title,
  customActionMenu,
  children
}) => {
  const theme = useTheme() as any
  const label = title || ""

  const blueGray = theme.neutral.blueGray

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <HeaderBox>
            <TitleHeaderBox>
              <NeoCenterFlex
                onClick={handleCloseDrawer}
                style={{ cursor: "pointer" }}
              >
                <NeoIcon size={12} name="close" fill={blueGray} />
              </NeoCenterFlex>
              <Title>{label}</Title>
              {customActionMenu && customActionMenu}
            </TitleHeaderBox>
          </HeaderBox>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default React.memo(DefaultDrawerHeader)
