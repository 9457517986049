export const applicationData = {
  success: [
    {
      SKUList: null,
      applicationCode: "Ruciika",
      applicationName: "Admin",
      applicationType: "",
      applicationURL: "/idm/identity",
      clusterTypes: null,
      default: true,
      description: "",
      entitlements: null,
      featureFlags: null,
      id: "3eeb7de0-509f-42cc-8b8d-7a4523502db7",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "admin",
          licenses: null,
          localizationKey: "Roles",
          name: "roles",
          scopes: [
            "idm.role.add",
            "idm.role.update",
            "idm.role.read",
            "idm.role.get",
            "idm.role.delete"
          ],
          skus: null,
          url: "/idm/identity/roles"
        },
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "user",
          licenses: null,
          localizationKey: "Users",
          name: "users",
          scopes: [
            "idm.user.add",
            "idm.user.read",
            "idm.user.get",
            "idm.user.update",
            "idm.user.delete"
          ],
          skus: null,
          url: "/idm/identity/users"
        },
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "tenant",
          licenses: null,
          localizationKey: "Groups",
          name: "groups",
          scopes: [
            "idm.group.add",
            "idm.group.read",
            "idm.group.update",
            "idm.group.delete"
          ],
          skus: null,
          url: "/idm/identity/groups"
        },
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "channel_network_share",
          licenses: null,
          localizationKey: "LDAP",
          name: "ldap",
          scopes: [
            "idm.idp.add",
            "idm.idp.search",
            "idm.idp.update",
            "idm.idp.get",
            "idm.idp.delete"
          ],
          skus: null,
          url: "/idm/identity/ldap"
        },
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "settings",
          licenses: null,
          localizationKey: "Settings",
          name: "settings",
          scopes: ["idm.tenantsettings.read", "idm.tenantsettings.write"],
          skus: null,
          url: "/settings/idm/auth"
        },
        {
          action: "",
          addOns: ["FlxAdminConsole"],
          featureFlags: [""],
          icon: "local-user",
          licenses: null,
          localizationKey: "Flex Edge",
          name: "flexedge",
          scopes: [""],
          skus: null,
          url: "/admin/devices#/en/flexedge?folderKey=flexedge_FlexEdgeDeviceElement"
        },
        {
          action: "",
          addOns: ["AdminConsole"],
          featureFlags: [""],
          icon: "cloud",
          licenses: null,
          localizationKey: "SAML",
          name: "saml",
          scopes: [
            "idm.idp.add",
            "idm.idp.search",
            "idm.idp.update",
            "idm.idp.get",
            "idm.idp.delete"
          ],
          skus: null,
          url: "/idm/identity/saml"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/identityui",
          location: "",
          moduleName: "Identity and Access Management",
          moduleURLPrefix: "/idm/identity",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-identityui.js",
            prefix: "https://{tenantHost}.idm.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/flexedge",
          location: "",
          moduleName: "FlexEdge",
          moduleURLPrefix: "/admin/devices",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/edge/edge.js?appName=admin",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/fe-deploy-changes",
          location: "header",
          moduleName: "FlexEdge Header",
          moduleURLPrefix: "/admin/devices",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/deploy-changes/deploy-changes.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/policiesui",
          location: "",
          moduleName: "Settings",
          moduleURLPrefix: "/settings/idm",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-policiesui.js",
            prefix: "https://{tenantHost}.idm.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [
        {
          description: "",
          featureFlags: null,
          group: "IDM",
          iconUrl: "shield",
          name: "Authentication",
          scopes: ["idm.tenantsettings:read"],
          skus: null,
          url: "/settings/idm/auth"
        },
        {
          description: "",
          featureFlags: null,
          group: "IDM",
          iconUrl: "bell1",
          name: "Notification Settings",
          scopes: ["idm.tenantsettings:read"],
          skus: null,
          url: "/settings/idm/notification-settings"
        }
      ],
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fpone/insights/userguide/index.html",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: [
        {
          code: "",
          description: "",
          groupName: "",
          name: ""
        }
      ],
      applicationCode: "DEP",
      applicationName: "Deployment",
      applicationType: "superAdmin",
      applicationURL: "/deployment/clusters",
      clusterTypes: [],
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "",
          name: "",
          scope: ""
        }
      ],
      featureFlags: null,
      id: "538dcb9b-bfc5-462e-8dea-6532d937e8c5",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "web",
          licenses: null,
          localizationKey: "Deployment",
          name: "deployment",
          scopes: [""],
          skus: null,
          url: "/deployment/clusters"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "shield",
          licenses: null,
          localizationKey: "Versions",
          name: "versions",
          scopes: [""],
          skus: null,
          url: "/deployment/versions"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/deploymentui",
          location: "",
          moduleName: "OPS Center Deployment",
          moduleURLPrefix: "/deployment",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-deploymentui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: false,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "FLX",
      applicationName: "FlexEdge",
      applicationType: "",
      applicationURL: "",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: null,
      featureFlags: null,
      id: "5f611841-a3a0-4f69-92b8-56735a796af8",
      iframeURL: "",
      isSPA: false,
      license: {
        addOns: [
          {
            addOnCode: "FlxAdminConsole",
            defaultEnabled: false,
            displayName: "Admin Console",
            metadata: ""
          }
        ],
        metadata: "",
        required: false
      },
      menuType: "none",
      menus: null,
      modules: null,
      openInIframe: false,
      openInNewTab: false,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: null,
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "SIDM",
      applicationName: "Identity \u0026 Access Management",
      applicationType: "superAdmin",
      applicationURL: "/idm/identity",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "Administrator",
          name: "SIDMAdmin",
          scope:
            "admin:*,application.read,idm.user.*,idm.group.*,idm.role.*,idm.spmetadata.get,idm.alert.read,idm.alert.markread,idm.smtp.validate,idm.alert.dismiss,idm.mfaconfiguration.write,idm.admin.write,featureflag.*,license.get,tenantsettings:*,idm.tenantsettings.*,application.read"
        },
        {
          description: "",
          displayName: "Read Only Administrator",
          name: "SIDMAdmin-Readonly",
          scope:
            "application.read,idm.user.read,idm.user.get,idm.group.read,idm.group:get,idm.role.read,idm.role.get,idm.spmetadata:get,idm.idp.search,idm.idp.get,idm.alert.read,featureflag.read,featureflag.get,tenantsettings:read,idm.tenantsettings.read,application.read,admin:read"
        }
      ],
      featureFlags: null,
      id: "6881a219-633f-47d3-bef8-42835466824a",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "admin",
          licenses: null,
          localizationKey: "Roles",
          name: "roles",
          scopes: [
            "idm.role.add",
            "idm.role.update",
            "idm.role.read",
            "idm.role.get",
            "idm.role.delete"
          ],
          skus: null,
          url: "/idm/identity/roles"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "user",
          licenses: null,
          localizationKey: "Users",
          name: "users",
          scopes: [
            "idm.user.add",
            "idm.user.read",
            "idm.user.get",
            "idm.user.update",
            "idm.user.delete"
          ],
          skus: null,
          url: "/idm/identity/users"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "tenant",
          licenses: null,
          localizationKey: "Groups",
          name: "groups",
          scopes: [
            "idm.group.add",
            "idm.group.read",
            "idm.group.update",
            "idm.group.delete"
          ],
          skus: null,
          url: "/idm/identity/groups"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/identityui",
          location: "",
          moduleName: "Identity and Access Management",
          moduleURLPrefix: "/idm/identity",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-identityui.js",
            prefix: "https://{tenantHost}.idm.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/platform-utilmodulesui3",
          location: "",
          moduleName: "Platform Util Module3",
          moduleURLPrefix: "/idm/settings",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-platform-utilmodulesui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/settingsui",
          location: "",
          moduleName: "Global Settings",
          moduleURLPrefix: "/settings/idm",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-settingsui.js",
            prefix: "https://{tenantHost}.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [
        {
          description: "",
          featureFlags: null,
          group: "IDM",
          iconUrl: "copy11",
          name: "Settings",
          scopes: ["idm.tenantsettings:read"],
          skus: null,
          url: "/settings/idm"
        }
      ],
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fpone/insights/userguide/index.html",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "RBI",
      applicationName: "Remote Browser Isolation",
      applicationType: "",
      applicationURL: "/rbi/dashboard",
      clusterTypes: [
        {
          code: "RBCC",
          components: [
            {
              canUpgradeFromConsole: true,
              name: "RBCC"
            },
            {
              canUpgradeFromConsole: true,
              name: "RBC"
            }
          ],
          detailPageURL: "",
          dnsMappingStrategy: "none",
          domainSuffix: "rbi.qa.forcepoint.net",
          healthCheckURL: "",
          name: "RBCC"
        },
        {
          code: "CCC",
          components: [
            {
              canUpgradeFromConsole: true,
              name: "CCC"
            }
          ],
          detailPageURL: "",
          dnsMappingStrategy: "none",
          domainSuffix: "rbi.qa.forcepoint.com",
          healthCheckURL: "",
          name: "CCC"
        }
      ],
      default: false,
      description: "",
      entitlements: [
        {
          description: "Admin entitlement for RBI",
          displayName: "RBI Administrator",
          name: "RBIAdmin",
          scope:
            "admin:*,rbi.controlcenter.*,-rbi.config.*,-rbi.sandboxes.*,-rbi.ca-certificate.*,rbi.admin.*,rbi.rendering.*,rbi.pixelrenderingpattern.*,rbi.download.*,rbi.upload.*,rbi.policyengine.*,rbi.session.*,rbi.device.*,rbi.tenantsettings.*,rbi.policyprofile.*,rbi.tenantSettings.*,rbi.overrideuseragent.*,rbi.sitevisits.*,rbi.rbc.*,rbi.policy.*,rbi.partnersetting.read,rbi.user.get,rbi.dlpsettings.*,rbi.downloads.write,rbi.downloads.read,rbi.uploads.write,rbi.uploads.read,rbi.filetransaction.complete, rbi.userscope.*,application.read,rbi.cookiedata.*,license.get,rbi.categoryOverride.*,alert.*,smtp.*"
        },
        {
          description: "Admin Readonly entitlement for RBI",
          displayName: "RBI Administrator Read only",
          name: "RBIAdmin-Readonly",
          scope:
            "rbi.controlcenter.*,rbi.*.read,rbi.*.stats,rbi.analytics.*,rbi.sitevisits.read,rbi.audittrails.read,rbi.downloads.read,rbi.uploads.read,rbi.messages.read,rbi.session.read,rbi.user.changepasswd,-rbi.recyclenode.*,-rbi.user.read,-rbi.usergroup.read,-rbi.mytenant.read,-rbi.policyprofile.read,-rbi.categoryOverride.read,-rbi.idprovider.read,-rbi.logaggregate.read,-rbi.sandboxes.read,rbi.ftis.*,-rbi.tenantSettingsMenu.read,-rbi.dlpSettingsMenu.read,rbi.overrideuseragent.read,license.get,admin:read,application.read,license.get,alert.read"
        },
        {
          description: "User entitlement for RBI",
          displayName: "RBI User",
          name: "RBIUser",
          scope:
            "admin:*,userscope:*,rbi.browse.*,rbi.cookiedata.read,rbi.cookiedata.write,rbi.downloads.read,rbi.downloads.write,rbi.uploads.read,rbi.uploads.write,rbi.device.read,rbi.device.write,rbi.device.delete,rbi.session.read,rbi.session.write,rbi.sitevisits.read,rbi.sitevisits.write,rbi.policyprofile.read,rbi.userpolicy.read,rbi.usergrouppolicy.read,rbi.tenantpolicy.read,rbi.node.read,rbi.nodeallocation.write,rbi.tenant.read,rbi.tenantSettings.read,rbi.pixelrenderingpattern.*,rbi.session.bandwidth-usage,rbi.dlpsettings.read,rbi.dlpincidents.write,rbi.adincidents.write,rbi.overrideisolationmode.read,rbi.switchedIsolationMode.write,rbi.overrideuseragent.read,rbi.switchedIsolationMode.write,rbi.idp.logout,rbi.idp.logout,user.get,userscope.*,rbi.partnersetting.read,rbi.policy.evaluate,rbi.policyprofile.get,rbi.session.add,rbi.policy.get,rbi.policy.read,rbi.tenantsettings.read"
        },
        {
          description: "Policy reader entitlement for RBI",
          displayName: "RBI Policy reader",
          name: "RBIPolicyreader",
          scope:
            "rbi.policyprofile.read,rbi.userpolicy.read,rbi.usergrouppolicy.read,rbi.tenantpolicy.read,application:read"
        },
        {
          description: "Policy writer entitlement for RBI",
          displayName: "RBI Policy writer",
          name: "RBIPolicywriter",
          scope:
            "rbi.policyprofile.read,rbi.userpolicy.read,rbi.usergrouppolicy.read,rbi.tenantpolicy.read,rbi.policyprofile.write,rbi.userpolicy.write,rbi.usergrouppolicy.write,rbi.tenantpolicy.write,application:read"
        }
      ],
      featureFlags: null,
      id: "7042c6f6-b065-43cd-8b3f-c327eb94983a",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: [
          {
            addOnCode: "RBISKU",
            defaultEnabled: true,
            displayName: "SKU",
            metadata:
              '{"type":"list","typeParam":"FRBIF,FRBIS","required":true}'
          },
          {
            addOnCode: "RBIFullUserCount",
            defaultEnabled: true,
            displayName: "Full User Count",
            metadata: '{"type": "number","default": 0,"required": true}'
          },
          {
            addOnCode: "RBISelectiveUserCount",
            defaultEnabled: true,
            displayName: "Selective User Count",
            metadata: '{"type": "number","default": 0,"required": true}'
          },
          {
            addOnCode: "RBIFeatures",
            defaultEnabled: true,
            displayName: "Features",
            metadata:
              '{"type": "list","typeParam": "core","required": true,"default": 0}'
          }
        ],
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: null,
          localizationKey: "Dashboard",
          name: "dashboard",
          scopes: ["rbi.session.read"],
          skus: null,
          url: "/rbi/dashboard"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "policy",
          licenses: null,
          localizationKey: "Policy",
          name: "policies",
          scopes: [
            "rbi.userpolicy.read",
            "rbi.usergrouppolicy.read",
            "rbi.tenantpolicy.read",
            "rbi.policyprofile.read",
            "rbi.policyprofile.write",
            "rbi.userpolicyprofiles.read",
            "rbi.usergrouppolicyprofiles.read",
            "rbi.tenantpolicyprofiles.read"
          ],
          skus: null,
          url: "/rbi/policies/policies"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "nav_account",
          licenses: null,
          localizationKey: "RBI Profile",
          name: "profile",
          scopes: [
            "rbi.policyprofile.read",
            "rbi.policyprofile.write",
            "rbi.userpolicyprofiles.read",
            "rbi.usergrouppolicyprofiles.read",
            "rbi.tenantpolicyprofiles.read"
          ],
          skus: null,
          url: "/rbi/policies/profile"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/rbidashboardui",
          location: "",
          moduleName: "RBI Dashboard",
          moduleURLPrefix: "/rbi/dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-rbi-dashboardui.js",
            prefix: "https://admincore.fp.dev"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/rbipoliciesui",
          location: "",
          moduleName: "Policies",
          moduleURLPrefix: "/rbi/policies",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-rbi-policiesui.js",
            prefix: "https://admincore.fp.dev"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/settingsui",
          location: "",
          moduleName: "Settings",
          moduleURLPrefix: "/settings/rbi",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-rbi-settingsui.js",
            prefix: "https://admincore.fp.dev"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [
        {
          description: "",
          featureFlags: null,
          group: "RBI",
          iconUrl: "copy11",
          name: "Security \u0026 Privacy",
          scopes: ["rbi.tenantsettings:read"],
          skus: null,
          url: "/settings/rbi/security-privacy-configuration"
        },
        {
          description: "",
          featureFlags: null,
          group: "RBI",
          iconUrl: "applications",
          name: "Override Category",
          scopes: ["rbi.categoryOverride:read"],
          skus: null,
          url: "/settings/rbi/override-category"
        },
        {
          description: "",
          featureFlags: null,
          group: "RBI",
          iconUrl: "user-added",
          name: "Override User Agent",
          scopes: ["rbi.overrideuseragent:read"],
          skus: null,
          url: "/settings/rbi/override-user-agent"
        },
        {
          description: "",
          featureFlags: null,
          group: "Integration",
          iconUrl: "c_other",
          name: "RBI OPSWAT",
          scopes: ["rbi.tenantsettings:read"],
          skus: null,
          url: "/settings/rbi/opswat"
        },
        {
          description: "",
          featureFlags: null,
          group: "Integration",
          iconUrl: "event_link",
          name: "SIEM",
          scopes: [
            "ins.logformat:read",
            "ins.logevent:read",
            "ins.siemserver:read",
            "ins.siemprofile:get"
          ],
          skus: null,
          url: "/settings/rbi/siem"
        }
      ],
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fpone/insights/userguide/index.html",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "clusterURL/api/tenants/{id}/callback",
        tenantEnable: "",
        tenantOnboarding: "clusterURL/api/tenants/callback",
        tenantUpdate: "clusterURL/api/tenants/{id}/callback"
      }
    },
    {
      SKUList: null,
      applicationCode: "IDM",
      applicationName: "Identity Access Management",
      applicationType: "tenant",
      applicationURL: "/idm/identity",
      clusterTypes: [
        {
          code: "IDM",
          components: [
            {
              canUpgradeFromConsole: true,
              name: "IDM"
            }
          ],
          detailPageURL: "https://portal.dev.forcepointone.com",
          dnsMappingStrategy: "specific",
          domainSuffix: "idm.dev.forcepointone.com",
          healthCheckURL: "",
          name: "IDM"
        }
      ],
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "Identity Administrator",
          name: "Admin",
          scope:
            "admin:*,application.read,idm.user.*,idm.group.*,idm.role.*,idm.spmetadata.get,idm.idp.*,idm.alert.read,idm.alert.markread,idm.smtp.validate,idm.alert.dismiss,idm.mfaconfiguration.write,idm.admin.write,featureflag.*,license.get,tenantsettings:*,idm.tenantsettings.*,idm.policy.*,idm.policymetadata.*,idm.policyprofile.*,application:read,idm.identityprovider.*,eula:search,eula:accept"
        },
        {
          description: "",
          displayName: "Identity Administrator Read only",
          name: "Admin-Readonly",
          scope:
            "admin:read,application.read,idm.user.read,idm.user.get,idm.group.read,idm.group:get,idm.role.read,idm.role.get,idm.spmetadata:get,idm.idp.search,idm.idp.get,idm.alert.read,featureflag.read,featureflag.get,tenantsettings:read,idm.tenantsettings.read,idm.policyprofile.get,idm.policy.read,application:read,idm.identityprovider.get"
        }
      ],
      featureFlags: null,
      id: "74f0b810-55d6-4cd7-b1fe-31f644073c95",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: [
          {
            addOnCode: "AdminConsole",
            defaultEnabled: true,
            displayName: "Admin Console",
            metadata: ""
          }
        ],
        metadata: "",
        required: true
      },
      menuType: "none",
      menus: null,
      modules: null,
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [],
      sharedModules: null,
      showInSwitcher: false,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "clusterURL/api/tenants/{id}/alternateID",
        tenantDelete: "clusterURL/api/tenants/{id}",
        tenantEnable: "",
        tenantOnboarding: "clusterURL/api/tenants",
        tenantUpdate: "clusterURL/api/tenants/{id}"
      }
    },
    {
      SKUList: null,
      applicationCode: "RBIH",
      applicationName: "Remote Browser Isolation",
      applicationType: "",
      applicationURL: "/rbi/dashboard",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: [
        {
          description: "Admin entitlement for RBI Admin",
          displayName: "",
          name: "RBIAdmin",
          scope:
            "admin:*,rendering:*,pixelrenderingpattern:*,download:*,upload:*,policyengine:*,session:*,device:*,tenantsettings:*,policyprofile:*,tenantSettings:*,overrideuseragent:*,sitevisits:*,rbc:*,policy:*,partnersetting:read,user:get,dlpsettings:*,downloads:write,downloads:read, uploads:write,uploads:read,filetransaction:complete,adincidents:write"
        }
      ],
      featureFlags: null,
      id: "86c7e889-de32-4788-981e-03c5f2dec084",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: [
          {
            addOnCode: "RBISKU",
            defaultEnabled: true,
            displayName: "SKU",
            metadata:
              '{"type":"list","typeParam":"FRBIF,FRBIS","required":true}'
          },
          {
            addOnCode: "RBIFullUserCount",
            defaultEnabled: true,
            displayName: "Full User Count",
            metadata: '{"type": "number","default": 0,"required": true}'
          },
          {
            addOnCode: "RBISelectiveUserCount",
            defaultEnabled: true,
            displayName: "Selective User Count",
            metadata: '{"type": "number","default": 0,"required": true}'
          },
          {
            addOnCode: "RBIFeatures",
            defaultEnabled: true,
            displayName: "Features",
            metadata:
              '{"type": "list","typeParam": "core","required": true,"default": 0}'
          }
        ],
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: null,
          localizationKey: "Dashboard",
          name: "dashboard",
          scopes: [""],
          skus: null,
          url: "/rbi/dashboard"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "policy",
          licenses: null,
          localizationKey: "Policy",
          name: "policy",
          scopes: [""],
          skus: null,
          url: "/rbi/policies"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "settings",
          licenses: null,
          localizationKey: "Settings",
          name: "settings",
          scopes: [""],
          skus: null,
          url: "/settings/rbi"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/rbidashboardui",
          location: "",
          moduleName: "RBI Dashboard",
          moduleURLPrefix: "/rbi/dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-rbi-dashboardui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/rbipoliciesui",
          location: "",
          moduleName: "Policies",
          moduleURLPrefix: "/rbi/policies",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-rbi-policiesui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/settingsui",
          location: "",
          moduleName: "Settings",
          moduleURLPrefix: "/settings/rbi",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-rbi-settingsui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: [
        {
          code: "",
          description: "",
          groupName: "",
          name: ""
        }
      ],
      applicationCode: "CLU",
      applicationName: "Cluster Management",
      applicationType: "superAdmin",
      applicationURL: "/opsc/cluster/network",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "Cluster Administrator",
          name: "CLRAdmin",
          scope: "admin:*,cluster:*,application:read"
        },
        {
          description: "",
          displayName: "Cluster Administrator Readonly",
          name: "CLRAdmin-Readonly",
          scope: "admin:read,cluster:search,cluster:get,application:read"
        }
      ],
      featureFlags: null,
      id: "89163169-8429-4490-99ee-fefd6315ad0d",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "web",
          licenses: null,
          localizationKey: "Network",
          name: "network",
          scopes: ["cluster:search"],
          skus: null,
          url: "/opsc/cluster/network"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "shield",
          licenses: null,
          localizationKey: "Clusters",
          name: "cluster",
          scopes: ["cluster:search"],
          skus: null,
          url: "/opsc/cluster"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/clusterui",
          location: "",
          moduleName: "OPS Center Cluster",
          moduleURLPrefix: "/opsc/cluster",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-clusterui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: false,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [],
      sharedModules: [
        {
          moduleName: "@forcepoint/platform-utilityui1",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-platform-utilityui.js",
            prefix: ""
          }
        }
      ],
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fpone/insights/userguide/index.html",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "SRBI",
      applicationName: "Remote Browser Isolation",
      applicationType: "superAdmin",
      applicationURL: "/rbi/dashboard",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: [
        {
          description: "Admin entitlement for RBI",
          displayName: "",
          name: "SRBIAdmin",
          scope:
            "admin:*,session:*,device:*,tenantsettings:*,policyprofile:*,license:get,config:*,policy:*"
        },
        {
          description: "Admin Readonly entitlement for RBI",
          displayName: "",
          name: "SRBIAdmin-Readonly",
          scope:
            "admin:*,session:get,device:get,tenantsettings:get,policyprofile:get,license:get,config:get"
        }
      ],
      featureFlags: null,
      id: "987f2a8c-dc69-42a6-95c2-fe6b31275df1",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: null,
          localizationKey: "Dashboard",
          name: "dashboard",
          scopes: [""],
          skus: null,
          url: "/rbi/dashboard"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/platform-utilmodulesui2",
          location: "",
          moduleName: "Platform Util Module2",
          moduleURLPrefix: "/rbi",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-platform-utilmodulesui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "HW",
      applicationName: "Hello World",
      applicationType: "",
      applicationURL: "/hw",
      clusterTypes: null,
      default: false,
      description: "This is a demo application",
      entitlements: null,
      featureFlags: null,
      id: "a288e49a-5af6-44c5-b643-37ca9717f5c5",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: null,
          icon: "dashboard",
          licenses: null,
          localizationKey: "Registration",
          name: "registration",
          scopes: null,
          skus: null,
          url: "/registration"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/registrationui",
          location: "",
          moduleName: "Registration",
          moduleURLPrefix: "/registration",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-registrationui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: null,
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "DEV-RBI",
      applicationName: "Remote Browser Isolation",
      applicationType: "",
      applicationURL: "/rbi/dashboard",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "",
          name: "DEV-RBI.Admin",
          scope: "*"
        }
      ],
      featureFlags: null,
      id: "aa1ba82d-b54a-414c-84c9-dce3a98d0872",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: null,
          localizationKey: "Dashboard",
          name: "dashboard",
          scopes: [""],
          skus: null,
          url: "/rbi/dashboard"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "policy",
          licenses: null,
          localizationKey: "Policy",
          name: "policy",
          scopes: [""],
          skus: null,
          url: "/rbi/policy"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "settings",
          licenses: null,
          localizationKey: "Settings",
          name: "settings",
          scopes: [""],
          skus: null,
          url: "/settings/rbi"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/platform-utilmodulesui",
          location: "",
          moduleName: "Platform Util Module",
          moduleURLPrefix: "/rbi",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-platform-utilmodulesui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/settingsui",
          location: "",
          moduleName: "Settings",
          moduleURLPrefix: "/settings/rbi",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-rbi-settingsui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [
        {
          moduleName: "@forcepoint/rbi-utilityui",
          moduleUrls: {
            apiURL: "",
            jsPath: "/assets/js/forcepoint-rbi-utilityui.js",
            prefix: ""
          }
        }
      ],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "SSE",
      applicationName: "Security Service Edge",
      applicationType: "external",
      applicationURL: "https://portal.dev1.fp1.dev/accounts/login/",
      clusterTypes: null,
      default: false,
      description: "",
      entitlements: null,
      featureFlags: null,
      id: "b7b46bb9-6a3d-4db1-9e17-4bd3df32385d",
      iframeURL: "",
      isSPA: false,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: null,
      modules: null,
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: [
        {
          code: "",
          description: "",
          groupName: "",
          name: ""
        }
      ],
      applicationCode: "FW",
      applicationName: "Firewall",
      applicationType: "",
      applicationURL: "/firewall/dashboard",
      clusterTypes: null,
      default: false,
      description: "Firewall App",
      entitlements: [
        {
          description: "Admin entitlement for Cloud Firewall",
          displayName: "",
          name: "fw.cloud.admin",
          scope: "admin:*,fw.cloud.read,fw.cloud.write"
        },
        {
          description: "Read only entitlement for Cloud Firewall",
          displayName: "",
          name: "fw.cloud.readonly",
          scope:
            "fw.cloud.read,eula:search,eula:accept,application:read,admin:read"
        }
      ],
      featureFlags: null,
      id: "b8452629-e0d8-4734-827b-e771b5cf4534",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: null,
          localizationKey: "Dashboard",
          name: "dashboard",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/dashboard"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "channel_event_log",
          licenses: null,
          localizationKey: "Logs",
          name: "logs",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/dashboard#/logs"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "export",
          licenses: null,
          localizationKey: "Log Export",
          name: "logexport",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/logexport"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "policy",
          licenses: null,
          localizationKey: "Policy",
          name: "policy",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/edge#/en/policy"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "c_system_log_events",
          licenses: null,
          localizationKey: "Deploy History",
          name: "deploy_history",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/deploy-history"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "circle",
          licenses: null,
          localizationKey: "Objects",
          name: "object",
          scopes: ["fw.cloud.read"],
          skus: null,
          url: "/firewall/edge#/en/objects"
        }
      ],
      modules: [
        {
          SPAName: "@polaris/deploy-changes",
          location: "header",
          moduleName: "Cloud Firewall Header",
          moduleURLPrefix: "/firewall",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/deploy-changes/deploy-changes.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/dashboard",
          location: "",
          moduleName: "Dashboard",
          moduleURLPrefix: "/firewall/dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/dashboard/dashboard.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/logmanagement",
          location: "",
          moduleName: "Log Export",
          moduleURLPrefix: "/firewall/logexport",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/logmanagement/logmanagement.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/edge",
          location: "",
          moduleName: "Edge",
          moduleURLPrefix: "/firewall/edge",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/edge/edge.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@polaris/deploy-history",
          location: "",
          moduleName: "Deploy History",
          moduleURLPrefix: "/firewall/deploy-history",
          moduleUrls: {
            apiURL: "",
            jsPath: "/protected/primary/apps/deploy-history/deploy-history.js",
            prefix: "https://dep.app.dev.msvokxavdlwatqovcwze-int.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: false,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [
        {
          description: "",
          featureFlags: null,
          group: "",
          iconUrl: "",
          name: "",
          scopes: ["fw.cloud.read", "fw.cloud.write"],
          skus: null,
          url: ""
        }
      ],
      sharedModules: null,
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fponefirewall/en-us/onlinehelp/",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: [
        {
          code: "",
          description: "",
          groupName: "",
          name: ""
        }
      ],
      applicationCode: "INS",
      applicationName: "Insights",
      applicationType: "",
      applicationURL: "/insights/dashboard",
      clusterTypes: [
        {
          code: "INS",
          components: [
            {
              canUpgradeFromConsole: true,
              name: "INS"
            }
          ],
          detailPageURL: "",
          dnsMappingStrategy: "specific",
          domainSuffix: "insights.dev.forcepointone.com",
          healthCheckURL: "",
          name: "INS"
        }
      ],
      default: false,
      description: "",
      entitlements: [
        {
          description: "",
          displayName: "Insights Administrator",
          name: "INS.Admin",
          scope:
            "admin:*,symphony.view,ins.symphony.*,ins.roi.*,ins.dashboard.*,ins.widget.*,ins.query.get,ins.reporting.add,ins.siemprofile.*,ins.logformat.*,ins.logformat.read,ins.logevent.read,ins.siemserver.read,ins.eventlog.add,ins.collections.*,ins.siemprofile.*,application.read,license.get,eula:search"
        },
        {
          description: "",
          displayName: "Insights Administrator Read only",
          name: "INS.Admin-Readonly",
          scope:
            "adminreadonly:*,ins.symphony.*,ins.roi.get,ins.dashboard.get,ins.widget.get,ins.query.get,ins.reporting.add,application.read,license.get,admin:read,eula:search"
        }
      ],
      featureFlags: null,
      id: "bdbe82ae-e62d-40a5-9ab0-5bc5ea939607",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: [
          {
            addOnCode: "Discover",
            defaultEnabled: false,
            displayName: "Discover",
            metadata: ""
          }
        ],
        metadata: "",
        required: true
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "dashboard",
          licenses: ["SSE"],
          localizationKey: "Summary",
          name: "summary",
          scopes: [
            "ins.roi.add",
            "ins.roi.update",
            "ins.roi.get",
            "ins.reporting.add",
            "ins.query.get",
            "ins.symphony.view"
          ],
          skus: null,
          url: "/insights/dashboard/summary"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "c_security",
          licenses: ["SSE"],
          localizationKey: "Data Security",
          name: "data_security",
          scopes: ["ins.symphony.view"],
          skus: null,
          url: "/insights/dashboard/data-security"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "investigate",
          licenses: ["SSE"],
          localizationKey: "Threat Protection",
          name: "threat_protection",
          scopes: ["ins.symphony.view"],
          skus: null,
          url: "/insights/dashboard/threat-protection"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "locker",
          licenses: ["RBI"],
          localizationKey: "RBI Dashboard",
          name: "rbi_dashboard",
          scopes: ["ins.query.get", "ins.reporting.add"],
          skus: null,
          url: "/insights/rbi/dashboard"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "channel_web",
          licenses: null,
          localizationKey: "Discover",
          name: "explore",
          scopes: ["ins.collections.get", "ins.query.get", "ins.symphony.view"],
          skus: null,
          url: "/insights/explore/discover"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "terminal",
          licenses: null,
          localizationKey: "Dashboard Catalog",
          name: "custom-dashboard",
          scopes: [
            "ins.dashboard.add",
            "ins.dashboard.delete",
            "ins.dashboard.update",
            "ins.dashboard.search",
            "ins.dashboard.get",
            "ins.widget.add",
            "ins.widget.update",
            "ins.widget.delete",
            "ins.widget.search",
            "ins.widget.get"
          ],
          skus: null,
          url: "/insights/custom-dashboard/dashboard-catalog"
        },
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "anomaly",
          licenses: null,
          localizationKey: "Widget Catalog",
          name: "widgets",
          scopes: [
            "ins.widget.add",
            "ins.widget.update",
            "ins.widget.delete",
            "ins.widget.search",
            "ins.widget.get"
          ],
          skus: null,
          url: "/insights/custom-dashboard/widget-catalog"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/insights-dashboardUI",
          location: "",
          moduleName: "Predefined Dasboards",
          moduleURLPrefix: "/insights/dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-insights-dashboardUI.js",
            prefix: "https://portal.insights.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/rbi-predashboardui",
          location: "",
          moduleName: "RBI Predefined Dasboards",
          moduleURLPrefix: "/insights/rbi/dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-rbi-predashboardui.js",
            prefix: "https://admincore.fp.dev"
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/insights-discoverUI",
          location: "",
          moduleName: "Explore",
          moduleURLPrefix: "/insights/explore",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-insights-discoverUI.js",
            prefix: "https://portal.insights.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/insights-customDashboardUI",
          location: "",
          moduleName: "Custom Dasboards",
          moduleURLPrefix: "/insights/custom-dashboard",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-insights-customDashboardUI.js",
            prefix: "https://portal.insights.dev.forcepointone.com"
          },
          uiConfig: {
            enableHelp: false,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: [],
      sharedModules: [
        {
          moduleName: "@forcepoint/insights-utilityUI",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-insights-utilityUI.js",
            prefix: "https://portal.insights.dev.forcepointone.com"
          }
        }
      ],
      showInSwitcher: true,
      supportUrls: {
        help: "https://help.forcepoint.com/fpone/insights/userguide/index.html",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "clusterURL/api/tenants/{id}/alternateID",
        tenantDelete: "clusterURL/api/tenants/{id}",
        tenantEnable: "",
        tenantOnboarding: "clusterURL/api/tenants",
        tenantUpdate: "clusterURL/api/tenants/{id}"
      }
    },
    {
      SKUList: null,
      applicationCode: "ORG",
      applicationName: "Organization",
      applicationType: "superAdmin",
      applicationURL: "/opsc/organization",
      clusterTypes: [],
      default: false,
      description: "",
      entitlements: [
        {
          description: "Superadmin entitlement",
          displayName: "",
          name: "Superadmin",
          scope: "*"
        }
      ],
      featureFlags: null,
      id: "d515f7a8-c96b-41cf-abed-dd71557ff30c",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "platform",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: [""],
          icon: "admin",
          licenses: null,
          localizationKey: "Organization",
          name: "organization",
          scopes: [""],
          skus: null,
          url: "/opsc/organization"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/tenantui",
          location: "",
          moduleName: "OPS Center Organization",
          moduleURLPrefix: "/opsc/organization",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-tenantui.js",
            prefix: ""
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: true,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: [
        {
          moduleName: "@forcepoint/platform-utilityui",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-platform-utilityui.js",
            prefix: ""
          }
        }
      ],
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    },
    {
      SKUList: null,
      applicationCode: "DOB",
      applicationName: "Document Browser",
      applicationType: "tenant",
      applicationURL: "/dob",
      clusterTypes: null,
      default: false,
      description: "Document Browser",
      entitlements: null,
      featureFlags: null,
      id: "feb2fe99-59da-4480-810d-e907d70fa032",
      iframeURL: "",
      isSPA: true,
      license: {
        addOns: null,
        metadata: "",
        required: false
      },
      menuType: "application",
      menus: [
        {
          action: "",
          addOns: null,
          featureFlags: null,
          icon: "dashboard",
          licenses: null,
          localizationKey: "HelloWorld",
          name: "hello",
          scopes: null,
          skus: null,
          url: "/dob"
        },
        {
          action: "",
          addOns: null,
          featureFlags: null,
          icon: "dashboard",
          licenses: null,
          localizationKey: "Browser",
          name: "browser",
          scopes: null,
          skus: null,
          url: "/browser"
        }
      ],
      modules: [
        {
          SPAName: "@forcepoint/dob",
          location: "",
          moduleName: "DOB",
          moduleURLPrefix: "/dob",
          moduleUrls: {
            apiURL: "",
            jsPath: "arb-demo/assets/Forcepoint-dom.js",
            prefix: "https://4w2xiiovbk.execute-api.us-east-1.amazonaws.com/"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        },
        {
          SPAName: "@forcepoint/registrationui",
          location: "",
          moduleName: "browser",
          moduleURLPrefix: "/browser",
          moduleUrls: {
            apiURL: "",
            jsPath: "/forcepoint-registrationui.js",
            prefix: "//localhost:8086"
          },
          uiConfig: {
            enableHelp: true,
            enableNotifications: false,
            enableSearch: false,
            enableSidebar: true
          }
        }
      ],
      openInIframe: false,
      openInNewTab: true,
      organizationSettingsURL: "",
      productLogo: "",
      settings: null,
      sharedModules: null,
      showInSwitcher: true,
      supportUrls: {
        help: "",
        notification: "",
        search: ""
      },
      webhooks: {
        applicationAdd: "",
        applicationUpdate: "",
        tenantAlternateIDUpdate: "",
        tenantDelete: "",
        tenantEnable: "",
        tenantOnboarding: "",
        tenantUpdate: ""
      }
    }
  ]
}
