import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const NoDataToDisplayTable = ({
  pageName,
  urlPrefix
}: {
  pageName?: string
  urlPrefix?: string
}) => {
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div>
            <div className="d-flex flex-column justify-content-center align-items-center h-350">
              <img
                alt="No Data Available"
                src={"/assets/images/table_no_data.svg"}
                height="130px"
              ></img>
              <p className="no-data-title mb-2 bold">
                {pageName
                  ? `No ${pageName} have been added yet`
                  : "No data to display"}
              </p>
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default NoDataToDisplayTable
