import * as singleSpa from "single-spa"
import { isEmpty } from "../../app.constants"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const NeoBreadcrumb = ({
  breadcrumbData,
  redirectTo
}: {
  breadcrumbData: { name: ""; url: ""; readOnly: boolean }[]
  redirectTo?: (url) => {}
}) => {
  /**
   * To redirect
   */
  const navigateTo = (url: string) => {
    singleSpa.navigateToUrl(url)
  }

  /**
   * UI Template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          {!isEmpty(breadcrumbData) ? (
            <nav>
              <ol className="app-breadcrumb">
                {breadcrumbData.map((item, index) => {
                  if (index === breadcrumbData.length - 1) {
                    return (
                      <li
                        key={item?.name}
                        className="app-breadcrumb-item active"
                        aria-current="page"
                      >
                        <a>{item?.name}</a>
                      </li>
                    )
                  } else {
                    return (
                      <li key={item?.name} className="app-breadcrumb-item">
                        {item?.readOnly ? (
                          <span>{item?.name}</span>
                        ) : (
                          <a
                            id={`breadcrumb_${item?.name}`}
                            className=""
                            onClick={() =>
                              redirectTo
                                ? redirectTo(item?.url)
                                : navigateTo(item?.url)
                            }
                          >
                            {item?.name}
                          </a>
                        )}
                      </li>
                    )
                  }
                })}
              </ol>
            </nav>
          ) : null}
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default NeoBreadcrumb
