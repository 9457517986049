import CardLoader from "../CardLoader/CardLoader"

type EmptyComponentProps = {
  filterInfo: Record<string, object>
  showSearchLoader?: boolean
}

const EmptyComponent: React.FC<EmptyComponentProps> = ({
  filterInfo,
  showSearchLoader
}) => {
  const render = (val) => (val ? <CardLoader /> : null)

  return Object.keys(filterInfo?.additionalInfo).includes("showHocLoader")
    ? render(filterInfo?.additionalInfo?.["showHocLoader"])
    : render(showSearchLoader)
}

export default EmptyComponent
