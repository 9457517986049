import { NeoPrimaryButton, NeoSecondaryButton } from "@neo/neo-common-ui/lib"
import { useState, useEffect } from "react"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

export interface FoneAlertInterface {
  title: string
  text: string
  renderHtml: any
  positiveBtnText: string
  showNegativeBtn?: string
  negativeBtnText?: string
  type: "success" | "warning" | "info" | "danger"
  positiveBtnCallback?: Function
  negativeBtnCallback?: Function
  data?: any
  disablePrimaryButton?: boolean
  size?: "small" | "medium" | "large"
}

const FoneAlert = ({ alertData }: { alertData: FoneAlertInterface }) => {
  const [alertState, setAlertState] = useState<FoneAlertInterface>()

  useEffect(() => {
    setAlertState(alertData)
  }, [alertData])

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div
            className={`fone-alert ${
              alertState?.type !== undefined ? "show-fone-alert" : ""
            }`}
          >
            <div
              className={`${alertState?.size === "small" ? "fone-alert-content-small" : alertState?.size === "large" ? "fone-alert-content-large" : "fone-alert-content-medium"}`}
            >
              <div className={`fone-alert-icon fone-alert-${alertState?.type}`}>
                <span> </span>
              </div>
              <div className="fone-alert-body">
                <h3 className="fone-alert-title">{alertState?.title}</h3>
                <p className="fone-alert-para">{alertState?.text}</p>
              </div>
              {alertData?.renderHtml ? <div>{alertData.renderHtml}</div> : null}
              <div className="fone-alert-footer">
                {alertState?.showNegativeBtn ? (
                  <NeoSecondaryButton
                    id="alert_negative_btn"
                    onClick={() => {
                      const resetAlertState = {} as FoneAlertInterface
                      setAlertState(resetAlertState)
                      alertState?.negativeBtnCallback(alertState?.data)
                    }}
                  >
                    {alertState?.negativeBtnText}
                  </NeoSecondaryButton>
                ) : null}
                <NeoPrimaryButton
                  id="alert_positive_btn"
                  onClick={() => {
                    alertState?.positiveBtnCallback(alertState?.data)
                  }}
                  isDisabled={alertState?.disablePrimaryButton}
                >
                  {alertState?.positiveBtnText}
                </NeoPrimaryButton>
              </div>
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default FoneAlert
