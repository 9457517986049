import { useNeoButtonPopupContext } from "@neo/neo-common-ui/lib/components/neo-popover/NeoButtonPopup"
import { FormattedMessage } from "react-intl"
import { MoreCell } from "../InlineDrawer/StyleComponents"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const TableColumnMoreBtn = ({ amount }: { amount: number }) => {
  const ctx = useNeoButtonPopupContext()
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <MoreCell id="popover_more_btn" isActive={ctx.isOpen}>
            <FormattedMessage
              id="event_details_list_more"
              defaultMessage="+ {amount} more"
              values={{ amount: amount }}
            />
          </MoreCell>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default TableColumnMoreBtn
