import { ItemCell, ItemCellText } from "../InlineDrawer/StyleComponents"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

export interface ItemBoxProps {
  item: {
    name: string
    color: string
    wrap?: boolean
  }
}

const ItemBox = ({ item }: ItemBoxProps) => {
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <ItemCell color={item.color}>
            <ItemCellText data-dark-tip={item.name} wrap={item.wrap}>
              {item.name}
            </ItemCellText>
          </ItemCell>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default ItemBox
