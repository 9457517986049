import styled, { css, keyframes } from "styled-components"
import { NeoCenterFlex } from "@neo/neo-common-ui/lib"

export const NoRowsComponent = styled(NeoCenterFlex)`
  background-color: ${({ theme }) => theme.neutral.white};
  flex: 1;
  height: 100%;
`

export const StyledNoRowsComponent = styled(NoRowsComponent)`
  padding: 50px;
  position: relative;
`

/**
 *
 * This wrapper shows his content only when the parent row is on hover state
 */
export const ShowCellOnRowHoverWrapper = styled.div<{
  disableHiding?: boolean
}>`
  ${({ disableHiding }) => {
    return disableHiding
      ? css`
          display: block;
        `
      : css`
          display: var(--row-hover-display);
        `
  }}
`

export const TruncateContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`

const spinAnimation = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`

export const SpinnerContainer = styled(NeoCenterFlex)`
  animation: ${spinAnimation} 1s infinite;
  transform-origin: center center;
`
