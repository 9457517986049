import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
const EXCEL_EXTENSION = ".xlsx"
const CSV_EXTENSION = ".csv"

export default class ExcelService {
  private static instance
  private constructor() {
    console.log("excel srv instance created")
  }
  public static getInstance(): ExcelService {
    if (!this.instance) {
      this.instance = new ExcelService()
    }
    return this.instance
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"]
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  public exportAsCSVFile(
    json: any[],
    excelFileName: string,
    headers = []
  ): void {
    let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    if (headers?.length > 0) {
      worksheet = XLSX.utils.sheet_add_aoa(worksheet, [headers])
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"]
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "csv",
      type: "array"
    })
    this.saveAsCSVFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    })
    FileSaver.saveAs(
      data,
      fileName + "_export_" + this.getDateTime() + EXCEL_EXTENSION
    )
  }

  private saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    })
    FileSaver.saveAs(
      data,
      fileName + "_export_" + this.getDateTime() + CSV_EXTENSION
    )
  }

  private getDateTime() {
    return new Date()
      .toString()
      .replace(/GMT.+/, "")
      .trim()
      .split(" ")
      .join("_")
  }
}
