import { NeoTable } from "@neo/neo-common-ui/lib"
import { useEffect, useState } from "react"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"
import SizeSelectionPagination from "./SizeSelectionPagination"
import NoDataToDisplayTable from "../NoDataToDisplayTable/NoDataToDisplayTable"
import { TextFilterComponent } from "./TextFilterComponent"
import { SelectedFilterComponent } from "./SelectedFilterComponent"
import { CheckboxFilterComponent } from "./CheckboxFilterComponent"
import { MultiSelectFilterComponent } from "./MultiSelectFilterComponent"
import RadioFilterComponent from "./RadioFilterComponent"
import { Dropdown } from "./Dropdown"
interface CustomTableProps {
  tableData: any[]
  tableHeaders: any[]
  isLoading?: boolean
  selectColumns?: boolean
  appPrefix?: any
  onChangeParam?: (params: any) => void
  handleRefresh?: () => void
  totalRecords: number
  onRowClick: any
  onRowRightClick: any
  onColumnResize: any
  pageSizeList: number[]
  pageSize?: number
  filteredColumns?: any
  id?: string
  hidePagination: boolean
  sortInfoCol?: any[]
  idKey?: string
  isExpanded?: boolean
  style: any
  isSticky: boolean
  styledRowComponent: any
  styledHeaderComponent: any
  activeRowKey: string
  loadingComponent: any
  isStatic: boolean
  onColumnsReorder: any
  trackScrolling: boolean
  testId: string
  emptyCellHyphen: boolean
  pageName?: string
}

/**
 * Holds all the filter components as key value pairs.
 * Key = user selection for filter type.
 * Value = The corresponding filter component.
 */
const filterComponentMap = {
  text: TextFilterComponent,
  select: SelectedFilterComponent,
  checkbox: CheckboxFilterComponent,
  multiselect: MultiSelectFilterComponent,
  radio: RadioFilterComponent,
  dropdown: Dropdown
}

export default function CustomNeoTable(props: CustomTableProps) {
  /**
   * To store page size
   */
  const pageSize = props.pageSize ?? 25

  /**
   * To store and set sort info columns
   */
  const [sortInfoCol, setSortInfoCol] = useState<any>([])

  /**
   * To store and set filter keys
   */
  const [filterKeys, setFilterKeys] = useState<any>({})

  /**
   * To store and set headers
   */
  const [headers, setHeaders] = useState([])

  /**
   * To set and store fetch table data
   */
  const [fetchTableData, setFetchTableData] = useState<boolean>(false)

  /**
   * To store and set list params
   */
  const [listParams, setListParams] = useState<{
    pageNo: number
    totalRecords: number
    size: number
    orderby: any[]
  }>({
    pageNo: 0,
    totalRecords: 0,
    size: pageSize,
    orderby: []
  })

  /**
   * Tp get sorting
   * @param newSortInfo
   */
  const getSorting = (newSortInfo: any) => {
    const elmIndex = sortInfoCol?.findIndex(
      (element) => element.columnKey === newSortInfo?.[0].columnKey
    )
    const tmpArr = [...sortInfoCol]
    if (elmIndex > -1) {
      if (sortInfoCol[elmIndex].sortOrder === "DESC") {
        tmpArr.splice(elmIndex, 1)
      } else {
        tmpArr[elmIndex].sortOrder = newSortInfo[0].sortOrder
      }
    } else {
      tmpArr.push(newSortInfo[0])
    }
    setSortInfoCol(tmpArr)
  }

  /**
   *
   * @param pageNo On change of pagination
   */
  const onHandlePagination = (pageNo) => {
    setListParams({ ...listParams, pageNo })
    setFetchTableData(!fetchTableData)
  }

  /**
   * To Refresh list with params
   */
  const refreshListWithParams = () => {
    if (props.onChangeParam) {
      props.onChangeParam({
        offset: listParams.size * listParams.pageNo,
        limit: listParams.size,
        orderby: sortInfoCol.map((val) => {
          const order = `${filterKeys[val.columnKey] ? filterKeys[val.columnKey] : val.columnKey},${val.sortOrder === "ASC" ? 0 : 1}`
          return order
        })
      })
    }
  }

  /**
   * List params and sort info col effects
   */
  useEffect(() => {
    refreshListWithParams()
  }, [fetchTableData, sortInfoCol])

  /**
   * Table headers and filtered columns effect
   */
  useEffect(() => {
    if (props.tableHeaders && props.tableHeaders.length > 0) {
      const obj1: any = {}
      for (const item of props.tableHeaders) {
        obj1[item.key] = item.filterKey
      }
      setFilterKeys(obj1)
      setHeaders(
        props.tableHeaders?.map((val: any) => {
          const obj2 = { ...val }
          if (obj2.filterType && !obj2.filterAdditionalInfo) {
            obj2.filterAdditionalInfo = {
              label: obj2.title,
              type: obj2.filterType,
              value: props.filteredColumns
            }
          }
          obj2.filterComponent = filterComponentMap[obj2.filterType]
          return obj2
        })
      )
    } else {
      setHeaders([])
    }
  }, [props.tableHeaders, props.filteredColumns])

  /**
   * Custom sort info columns effect
   */
  useEffect(() => {
    if (props?.sortInfoCol) {
      setSortInfoCol(props?.sortInfoCol)
    }
  }, [props?.sortInfoCol])

  /**
   * On Search pagination reset
   */
  useEffect(() => {
    if (!props?.isLoading) {
      setListParams({ ...listParams, pageNo: 0 })
    }
  }, [props.totalRecords])

  /**
   * UI Template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="w-100 h-100">
            <NeoTable
              id={props?.id ? props.id : "custom-table"}
              columns={headers}
              rows={props.tableData}
              style={props.style}
              isSticky={props.isSticky}
              idKey={props.idKey || "id"}
              styledRowComponent={props.styledRowComponent}
              styledHeaderComponent={props.styledHeaderComponent}
              activeRowKey={props.activeRowKey}
              sortInfo={sortInfoCol}
              sortCallback={getSorting}
              filteredColumns={props.filteredColumns}
              noRowComponent={
                <NoDataToDisplayTable pageName={props.pageName} />
              }
              loadingComponent={props.loadingComponent}
              rowClickedHandler={({ record }) => props.onRowClick?.(record)}
              rowRightClickedHandler={({ record }) =>
                props.onRowRightClick?.(record)
              }
              isLoading={props.isLoading}
              isStatic={props.isStatic}
              emptyCellHyphen={props.emptyCellHyphen || true}
              selectColumns={props.selectColumns}
              isExpanded={props.isExpanded || false}
              onColumnResize={({ column, calculatedWidth, event }) =>
                props.onColumnResize?.(column, calculatedWidth, event)
              }
              onColumnsReorder={(data) => props.onColumnsReorder?.(data)}
              trackScrolling={props.trackScrolling}
              testId={props.testId}
              footerComponent={
                <>
                  {!props.hidePagination ? (
                    <SizeSelectionPagination
                      pageIndex={listParams.pageNo}
                      pageSize={listParams.size}
                      totalItems={props.totalRecords ?? 0}
                      pageChangeHandler={onHandlePagination}
                      pageSizeChangeHandler={(size) => {
                        setListParams({ ...listParams, size, pageNo: 0 })
                        setFetchTableData(!fetchTableData)
                      }}
                      isLoading={props.isLoading}
                      refreshCallback={refreshListWithParams}
                      pageSizeList={props.pageSizeList}
                    />
                  ) : null}
                </>
              }
            />
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
