import React, { useEffect, useState, useRef } from "react"
import {
  calculateStartEndDate,
  DATE_SELECTION_LIST,
  DATE_STORE,
  setDateRange
} from "../../app.constants"
import { NeoComponents } from "../../forcepoint-platform-utilityui"
import { RangeDateFilterComponent } from "./RangeDateFilter"
import { NeoPopupPosition } from "@neo/neo-common-ui/lib/components/neo-popover/NeoButtonPopup"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Header = (props: any) => {
  /**
   * To store date selection list
   */
  const dateSelectionList = structuredClone(DATE_SELECTION_LIST)

  /**
   * To store and set stard date
   */
  const [startDate, setStartDate] = useState("")

  /**
   * To store and set end date
   */
  const [endDate, setEndDate] = useState("")

  /**
   * To store and set start time
   */
  const [startTime, setStartTime] = useState(null)

  /**
   * To store end time
   */
  const [endTime, setEndTime] = useState(null)

  /**
   * To store and set range selection
   */
  const [showRangeSelection, setShowRangeSelection] = useState(false)

  /**
   * To store current period and set current period
   */
  const [currentPeriod, setCurrentPeriod] = useState<any>()

  /**
   * To store and set interval
   */
  const [storeInterval, setStoreInterval] = useState(null)

  /**
   * On Int effect
   */
  useEffect(() => {
    if (props.showDateFilter) {
      const sessionDateData = JSON.parse(
        sessionStorage.getItem("sessionDateData")
      )
      const selectedPeriod =
        dateSelectionList.find(
          (a) => a.value === (sessionDateData?.currentPeriod?.value || "30")
        ) || dateSelectionList[4]
      setCurrentPeriod(selectedPeriod)
      calculateStartEndDateValue(selectedPeriod, true)
      if (selectedPeriod?.value === "1H") {
        startEndTime()
      } else {
        clearInterval(storeInterval)
      }
    }
  }, [])

  /**
   * To Calculate start and end time
   */
  const startEndTime = () => {
    function calcStartEndTime() {
      const endDateTime = new Date()
      const startDateTime = new Date(endDateTime.getTime() - 3600000)
      setStartTime(
        `${("0" + startDateTime.getHours()).slice(-2)}:${(
          "0" + startDateTime.getMinutes()
        ).slice(-2)}`
      )
      setEndTime(
        `${("0" + endDateTime.getHours()).slice(-2)}:${(
          "0" + endDateTime.getMinutes()
        ).slice(-2)}`
      )
    }
    calcStartEndTime()
    const storeIntervalData = setInterval(() => {
      calcStartEndTime()
    }, 60000)
    setStoreInterval(storeIntervalData)
  }

  /**
   * To calculate start and end date value
   * @param val
   * @param isInitialLoad
   */
  const calculateStartEndDateValue = (selectedPeriod, isInitialLoad) => {
    const startEndDate = calculateStartEndDate(selectedPeriod)
    setStartDate(startEndDate.startDate)
    setEndDate(startEndDate.endDate)
    const sessionDateData = JSON.parse(
      sessionStorage.getItem("sessionDateData")
    )
    const val =
      sessionDateData.currentPeriod.value === "range"
        ? sessionDateData.category
        : currentPeriod?.value
    props.dateChange({ startEndDate, val, isInitialLoad })
  }

  /**
   * Period change event
   * @param e
   * @returns
   */
  const periodChange = (val: any) => {
    if (val.value === "range") {
      setShowRangeSelection(true)
      setCurrentPeriod(val)
      return
    }
    const sessionDateData = JSON.parse(
      sessionStorage.getItem("sessionDateData")
    )
    sessionDateData.currentPeriod = val
    sessionDateData.category = val.value
    sessionStorage.setItem("sessionDateData", JSON.stringify(sessionDateData))
    setShowRangeSelection(false)
    setCurrentPeriod(val)
    calculateStartEndDateValue(val, false)
    if (val.value === "1H") {
      startEndTime()
    } else {
      clearInterval(storeInterval)
    }
  }

  /**
   * Action btn handler
   * @param item
   */
  const actionBtnHandler = (item) => {
    props.actionCallback(item.key)
  }

  /**
   * Get Option btn
   * @param props
   * @returns
   */
  const GetOptionBtn = (props) => {
    let openMenu = false
    const optionContainerRef = useRef(null)
    const menuPopup: any = {}
    if (props.menuPopup && props.menuPopup.menuItems) {
      menuPopup.menuItems = props.menuPopup.menuItems.map((val) => {
        val.menuCallback = () => actionBtnHandler(val)
        return val
      })
    }

    const menuConfig = {
      ...props,
      menuPopup,
      onToggle: () => {
        openMenu = !openMenu
      }
    }
    return (
      <div
        ref={optionContainerRef}
        className="hover-option-menu"
        onMouseEnter={() => {
          if (!openMenu) {
            optionContainerRef.current
              .querySelector("#" + props.menuButton.id)
              ?.click()
            openMenu = true
          }
        }}
        onMouseLeave={() => {
          if (openMenu) {
            optionContainerRef.current
              .querySelector("#" + props.menuButton.id)
              ?.click()
            openMenu = false
          }
        }}
      >
        <NeoComponents.NeoPopupMenu
          {...menuConfig}
          buttonId={"header-options-menu"}
        />
      </div>
    )
  }

  /**
   * Action btn list
   * @returns
   */
  const ActionBtnList = () => {
    return props?.actionList?.map((btn, index) => {
      let btnComponent = null
      if (btn.type === "primary") {
        btnComponent = (
          <NeoComponents.NeoPrimaryButton
            key={btn.key}
            onClick={() => actionBtnHandler(btn)}
            iconName={btn?.iconName}
            isDisabled={btn?.isDisabled}
          >
            {btn.displayName}
          </NeoComponents.NeoPrimaryButton>
        )
      } else if (btn.type === "secondary") {
        btnComponent = (
          <NeoComponents.NeoSecondaryButton
            key={btn.key}
            iconName={btn?.iconName}
            isDisabled={btn?.isDisabled}
            onClick={() => actionBtnHandler(btn)}
          >
            {btn.displayName}
          </NeoComponents.NeoSecondaryButton>
        )
      } else if (btn.type === "options") {
        return <GetOptionBtn key={btn.key} {...btn.config} />
      }
      if (index + 1 !== props.actionList.length) {
        btnComponent = [btnComponent, <span className="me-4"></span>]
      }
      return btnComponent
    })
  }

  /**
   * To check if custom dahboard page or not
   */
  const isCustomDashboardPage = props?.actionList || false

  /**
   * To store date range changed
   * @param data
   */
  const dateRangeChange = (data) => {
    const dateObj = setDateRange(data.startDate, data.endDate)
    setStartDate(dateObj.startDate)
    setEndDate(dateObj.endDate)
    props.dateChange()
  }

  /**
   * Cancel Close
   */
  const cancelClose = () => {
    const selection = dateSelectionList.find(
      (a) => a.value === DATE_STORE.FREQUENCY.toString()
    )
    if (selection !== undefined) {
      periodChange(selection)
    }
    setShowRangeSelection(false)
  }

  /**
   * UI Template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div
            className={`symphony-header-container ${
              props?.showLoader ? "selected-explore-block" : ""
            }`}
          >
            <div className="symphony-header">
              {props.tabConfig ? (
                <div className="d-flex align-items-center">
                  <h3
                    className={
                      "sh-tab-title " +
                      (isCustomDashboardPage ? "cd-page-title" : "")
                    }
                  >
                    {props.pageTitle}
                  </h3>
                  <div
                    style={{ display: "flex" }}
                    className={props?.disabled ? "apply-disabled-tab" : ""}
                  >
                    <NeoComponents.NeoAnimationTabs
                      style={{ width: "auto" }}
                      onClick={(data) => {
                        if (props.onTabChange) {
                          props.onTabChange(data)
                        }
                      }}
                      selectedTabId={props.selectedTab}
                      tabs={props.tabConfig}
                    />
                  </div>
                </div>
              ) : (
                <h3
                  className={
                    "sh-title " + (isCustomDashboardPage ? "cd-page-title" : "")
                  }
                >
                  {props.pageTitle}
                </h3>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                {props.showDateFilter ? (
                  <>
                    <p style={{ marginBottom: 0 }}>
                      {currentPeriod?.value === "1H" ? (
                        <>
                          {endDate}, {startTime} - {endTime}
                        </>
                      ) : (
                        <>
                          {startDate} - {endDate}{" "}
                        </>
                      )}
                    </p>
                    <div
                      className={`symphony-header-select ${
                        isCustomDashboardPage || props.showPDFExportBtn
                          ? "show-right-vl"
                          : ""
                      }`}
                    >
                      <span>
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="time_range"
                          onClick={function noRefCheck() {}}
                          size={12}
                        />
                      </span>
                      <div className="header-entries-select">
                        <NeoComponents.NeoSelect
                          optionList={dateSelectionList}
                          selectedOption={currentPeriod}
                          displayMinWidth={100}
                          dropListPosition={NeoPopupPosition.BOTTOM_START}
                          style={{}}
                          onChange={(e) => periodChange(e)}
                        />
                      </div>
                      {showRangeSelection ? (
                        <>
                          <div className="neo-range-popover">
                            <RangeDateFilterComponent
                              {...{
                                startDate:
                                  currentPeriod?.value === "range"
                                    ? ""
                                    : startDate,
                                endDate:
                                  currentPeriod?.value === "range"
                                    ? ""
                                    : endDate,
                                dateRangeSelected:
                                  currentPeriod?.value === "range",
                                closeCallback: (data) => {
                                  dateRangeChange(data)
                                  setShowRangeSelection(false)
                                },
                                cancelCallback: () => {
                                  cancelClose()
                                },
                                disableFutureDate: props.disableFutureDate
                              }}
                            />
                          </div>
                          <div
                            className="neo-range-popover-overlay"
                            onClick={() => {
                              cancelClose()
                            }}
                          ></div>
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null}
                {props?.showActionBtn ? (
                  <div
                    style={{ marginRight: "0.5rem" }}
                    className={
                      props?.actionClassName ? `${props?.actionClassName}` : ""
                    }
                    data-always="true"
                    data-dark-tip={
                      props?.actionTitle ? props?.actionTitle : "Save"
                    }
                    data-dark-tip-at="left"
                  >
                    <NeoComponents.NeoIcon
                      fill={props?.actionFill ? props?.actionFill : "#00af9a"}
                      name={props?.actionIcon ? props?.actionIcon : "download"}
                      style={
                        props?.actionStyle
                          ? { ...props?.actionStyle, cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                      onClick={props?.handleActionSubmit}
                      size={20}
                    />
                  </div>
                ) : null}
                {props.showPDFExportBtn ? (
                  <div
                    className={
                      props?.pdfClassName ? `${props?.pdfClassName}` : ""
                    }
                    data-always="true"
                    data-dark-tip={
                      props?.pdfTitle ? props?.pdfTitle : "Export to PDF"
                    }
                    data-dark-tip-at="bottom-right"
                  >
                    <NeoComponents.NeoIcon
                      id="export-to-pdf"
                      fill={props?.pdfFill ? props?.pdfFill : "#00af9a"}
                      name="export"
                      style={{
                        cursor: "pointer",
                        marginRight: `${isCustomDashboardPage ? "1rem" : "0"}`
                      }}
                      onClick={props.exportPDF}
                      size={20}
                    />
                  </div>
                ) : null}
                {isCustomDashboardPage ? <ActionBtnList /> : null}
              </div>
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default React.memo(Header)
