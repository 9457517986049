function isRejectedAction(action) {
  return action.type.endsWith("rejected")
}

function isFulfilledAction(action) {
  return action.type.endsWith("fulfilled")
}

function isPendingAction(action) {
  return action.type.endsWith("pending")
}

function getHeaders(headersData) {
  const headers = ["x-total-count"]
  return headers.reduce((obj, val) => {
    obj[val] = headersData[val]
    return obj
  }, {})
}

function parseCustomPromiseResponse(arr) {
  let resList = []
  if (arr?.length > 0) {
    resList = arr.map((item) => {
      if (item.status === "fulfilled") return item.value.data
      else return null
    })
  }
  return resList
}

export const asyncAPIReducers = (reducersObj) => (builder) => {
  builder.addDefaultCase((state, action) => {
    if (action.payload?.headers) {
      action.meta.headers = getHeaders(action.payload.headers)
      action.payload = action.payload.data
    }
    if (action.payload?.custom) {
      action.payload = parseCustomPromiseResponse(action.payload.promise)
    }

    const type = action.type.split("/")[0]
    if (isFulfilledAction(action) && reducersObj[type]?.success) {
      reducersObj[type].success(state, action)
    } else if (isPendingAction(action) && reducersObj[type]?.pending) {
      reducersObj[type].pending(state, action)
    } else if (isRejectedAction(action) && reducersObj[type]?.rejected) {
      reducersObj[type].rejected(state, action)
    } else {
      if (reducersObj[type]?.success) {
        reducersObj[type].success(state, action)
      }
      if (reducersObj[type]?.pending) {
        reducersObj[type].pending(state, action)
      }
      if (reducersObj[type]?.rejected) {
        reducersObj[type].rejected(state, action)
      }
    }
  })
}
