export const messages = {
  en: {
    menu_user: "Create User",
    menu_user_mgmt: "User Management",
    menu_access_mgmt: "Access Management",
    menu_identity_mgmt: "Identity Management",
    menu_threat_protection: "Threat Protection",
    menu_data_security: "Data security",
    menu_dashboard: "Dashboard",
    menu_Investigator: "Search",
    menu_document: "Notes",
    menu_endpoints_outline: "Listing",
    menu_settings: "Setting",
    home_category_bubble_tooltip: "{name} {amount} alerts",
    table_no_data: "No data to display",
    table_no_data_subtitle: "We’re monitoring activity and will begin",
    table_no_data_subtitle_2nd_line:
      "recording data as soon as an alert is triggered."
  }
}
