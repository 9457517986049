import { NeoComponents, isEmpty } from "../../forcepoint-platform-utilityui"
import styled from "styled-components"
import { useEffect, useState } from "react"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 3px 8px rgba(151, 170, 186, 0.24);
  border-radius: 8px;
  border: 0.5px solid #d4dce2;
  display: block;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`

/**
 * Range DAte filter component
 * @param props
 * @returns
 */
export function RangeDateFilterComponent(props) {
  /**
   * To store max date value
   */
  const maxDate = new Date().toISOString().split("T")[0]

  /**
   * To store and set filter val
   */
  const [filterVal, setFilterVal] = useState({
    startDate: null,
    endDate: null
  })

  /**
   * date range selected props
   */
  useEffect(() => {
    if (props.dateRangeSelected) {
      setFilterVal({ startDate: props.startDate, endDate: props.endDate })
    }
  }, [props.dateRangeSelected])

  /**
   * Update selected filter
   */
  const updateSelectedFilter = () => {
    if (filterVal.endDate && filterVal.startDate) {
      props.closeCallback({
        range: true,
        startDate: filterVal.startDate,
        endDate: filterVal.endDate
      })
      return
    }
  }

  /**
   * Close method
   */
  const clear = () => {
    setFilterVal({
      startDate: null,
      endDate: null
    })
  }

  /**
   * UI template
   */
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="report-filter">
            <Container>
              <div className="date-range-input" style={{ width: "100%" }}>
                <NeoComponents.NeoIcon
                  name="close"
                  size={10}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    props.cancelCallback()
                  }}
                />
                <NeoComponents.NeoInput
                  {...{
                    id: "startDate",
                    containerStyle: { color: "#29343D" },
                    type: "date",
                    maxLength: 128,
                    label: "Start Date" + ":",
                    value: filterVal.startDate,
                    onKeyDown: (e) => e.preventDefault(),
                    onChange: (event) =>
                      setFilterVal({
                        startDate: event.value,
                        endDate: maxDate
                      }),
                    max: props.disableFutureDate ? maxDate : null
                  }}
                />
                <NeoComponents.NeoInput
                  {...{
                    id: "endDate",
                    containerStyle: { color: "#29343D" },
                    type: "date",
                    label: "End Date" + ":",
                    value: filterVal.endDate,
                    disabled: isEmpty(filterVal.startDate),
                    onKeyDown: (e) => e.preventDefault(),
                    onChange: (event) =>
                      setFilterVal({
                        startDate: filterVal.startDate,
                        endDate: event.value
                      }),
                    min: filterVal.startDate,
                    max: props.disableFutureDate ? maxDate : null
                  }}
                />
              </div>
              <ButtonGroup>
                <NeoComponents.NeoSecondaryButton
                  isDisabled={!(filterVal.startDate || filterVal.endDate)}
                  onClick={() => clear()}
                >
                  Clear
                </NeoComponents.NeoSecondaryButton>
                <NeoComponents.NeoPrimaryButton
                  onClick={() => updateSelectedFilter()}
                  isDisabled={!(filterVal.startDate && filterVal.endDate)}
                >
                  Apply
                </NeoComponents.NeoPrimaryButton>
              </ButtonGroup>
            </Container>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
