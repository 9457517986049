import { useRef, useMemo } from "react"
import { NeoComponents } from "../../forcepoint-platform-utilityui"
import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

const ActionMenu = ({ actionItems, iconName = "meatballs" }) => {
  const memoizedMenuItems = useMemo(() => actionItems, [actionItems])

  /**
   * To store edit actions
   */
  const editActions = {
    default: [
      {
        type: "options",
        displayName: "Options",
        key: "options",
        config: {
          menuButton: {
            borderRadius: "4px",
            iconName,
            id: "edit",
            position: "absolute",
            zIndex: "11000"
          },
          menuPopup: {
            menuItems: memoizedMenuItems
          }
        }
      }
    ]
  }

  /**
   * To get option button
   * @param props
   * @returns
   */
  const GetOptionBtn = (props) => {
    let openMenu = false
    const optionContainerRef = useRef(null)
    const menuPopup: any = {}
    if (props.menuPopup && props.menuPopup.menuItems) {
      menuPopup.menuItems = props.menuPopup.menuItems.map((val) => {
        val.menuCallback = val.onClick
        return val
      })
    }

    const menuConfig = {
      ...props,
      menuPopup,
      onToggle: () => {
        openMenu = !openMenu
      }
    }
    return (
      <div
        ref={optionContainerRef}
        className="hover-option-menu"
        onMouseEnter={() => {
          if (!openMenu) {
            optionContainerRef.current.querySelector("#edit").click()
            openMenu = true
          }
        }}
        onMouseLeave={() => {
          if (openMenu) {
            optionContainerRef.current.querySelector("#edit").click()
            openMenu = false
          }
        }}
      >
        <NeoComponents.NeoPopupMenu
          {...menuConfig}
          buttonId={"header-options-menu"}
        />
      </div>
    )
  }

  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <GetOptionBtn {...editActions.default[0].config} />
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}

export default ActionMenu
