import NeoErrorBoundary from "@neo/neo-common-ui/lib/components/neo-error-boundary"
import NeoThemeProvider from "@neo/neo-common-ui/lib/components/neo-theme/NeoThemeProvider"
import { Intl, messages } from "../../forcepoint-platform-utilityui"

export default function CardLoader(props: any) {
  return (
    <NeoErrorBoundary>
      <NeoThemeProvider>
        <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
          <div className="card-block-loader" style={{ ...props.style }}>
            <div className="blockUI"></div>
            <div className="sk-swing sk-primary">
              <div className="sk-swing-dot"></div>
              <div className="sk-swing-dot"></div>
            </div>
          </div>
        </Intl.IntlProvider>
      </NeoThemeProvider>
    </NeoErrorBoundary>
  )
}
